<template>
  <div
    v-if="fullRecorder"
    class="tw-w-full tw-bg-black tw-absolute tw-z-20 tw-h-screen tw-top-0 tw-left-0 tw-flex tw-flex-col tw-justify-center tw-items-center"
  >
    <p
      class="tw-mx-auto tw-text-center tw-w-full tw-text-white tw-text-2xl tw-my-5"
    >
      <span v-if="showInterval && !recordingStarted && !recordingCompleted">
        Your recording will start in {{ countdownValue }}
      </span>
      <span v-else-if="recordingStarted && !recordingCompleted">
        Recording...
      </span>
    </p>
    <div
      class="tw-relative  tw-overflow-hidden tw-w-full tw-flex tw-mt-4 tw-cursor-pointer tw-justify-center tw-items-center tw-mx-auto"
    >
      <div class="tw-w-[95%] tw-mx-auto  tw-flex tw-justify-center tw-items-center tw-max-w-[998px] ">
        <div class="tw-absolute tw-top-0 tw-rounded-[20px] tw-w-[1140px] tw-h-full tw-backdrop-brightness-[0.6] tw-backdrop-blur-[6px]  tw-bg-transparent tw-z-10 " />
        <video
          ref="videoElementOne"
          style="transform:scaleX(-1)"
          class="tw-w-[1140px] tw-h-auto tw-rounded-[20px] tw-bg-black/10"
          autoplay
        />
      </div>
      <video
        ref="videoElement"

        class="tw-w-[460px] tw-rounded-[20px] tw-h-[95%] tw-scale-x-[-1] tw-z-20 tw-border-[3px]  tw-border-white tw-border-dashed tw-object-cover tw-absolute  "
        autoplay
      />
    </div>
    <div class="tw-my-8">
      <div class="tw-w-[360px] tw-h-[6px] tw-overflow-hidden tw-rounded-full tw-bg-black-three tw-relative tw-mx-auto">
        <div
          class="tw-h-[6px] tw-rounded-full tw-bg-white "
          :style="{width: getAudioPercentage} "
        />
      </div>
      <div class="tw-w-full tw-flex tw-justify-center tw-space-x-3 tw-mt-5">
        <button
          class="tw-text-white tw-border tw-border-solid tw-border-white tw-rounded-xl tw-p-3"
          @click="cancelRecording"
        >
          Cancel
        </button>
        <button
          class="tw-bg-white tw-rounded-xl tw-p-3"
          :disabled="countdownValue > 0"
          @click="stopRecording"
        >
          Stop recording
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { AhButton } from '../../common'
export default {
  components: {
    AhButton
  },
  props: {
    seconds: {
      type: Number,
      required: false,
      default: 30
    },
    fullRecorder: {
      type: Boolean,
      required: false,
      default: false
    }

  },

  data () {
    return {
      recordingStarted: false,
      mediaRecorder: null,
      recordedChunks: [],
      readyToRecord: false,
      countdownValue: 5,
      showInterval: false,
      // playingVideo: false,
      recordingCompleted: false,
      currentRecordingSec: 0,
      showRecordingProgress: false,
      errorMessage: '',
      showError: false,
      showRecorder: false,
      recordingIntervalId: null,
      showFullRecorder: true
    }
  },
  computed: {
    getAudioPercentage () {
      return `${this.currentRecordingSec * 100 / this.seconds}%`
    }
  },
  mounted () {
    this.checkPermissionAndInitializeRecorder()
  },

  methods: {

    async checkPermissionAndInitializeRecorder () {
      try {
        // Check if the user has already granted permission for the media devices
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true })

        // Permission already granted, initialize the recorder
        await this.initializeRecorder()
        await this.startCountdown()
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.showError = true
          this.errorMessage = 'Please allow access to camera and microphone by going to browser settings'
          console.log('User has not granted permission for microphone and video by going to browser settings')
          // Handle the case where permission is not granted
        } else {
          console.error('Error accessing camera and microphone:', error)
        }
      }
    },

    async initializeRecorder () {
      try {
        this.$refs.videoElement.muted = true
        this.$refs.videoElementOne.muted = true
        this.readyToRecord = true
        const videoElement = this.$refs.videoElement
        const videoElementOne = this.$refs.videoElementOne
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        videoElement.srcObject = mediaStream
        videoElementOne.srcObject = mediaStream
        this.showError = false
        videoElement.style.transform = 'scaleX(-1)'
        this.errorMessage = ''
        videoElement.play()
        videoElementOne.play()
      } catch (error) {
        console.error('Error accessing camera and microphone:', error)
      }
    },

    async startRecording () {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop()
      }
      if (!this.readyToRecord) {
        this.initializeRecorder()
      }
      this.$refs.videoElement.muted = true
      this.showInterval = false
      this.recordingStarted = true
      this.recordingCompleted = false
      this.currentRecordingSec = 0
      this.recordedChunks = []
      this.showRecordingProgress = true
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        this.$refs.videoElement.srcObject = stream
        this.mediaRecorder = new MediaRecorder(stream)
        this.mediaRecorder.ondataavailable = (event) => {
          this.recordedChunks.push(event.data)
        }
        this.mediaRecorder.start()
        this.recordingIntervalId = setInterval(() => {
          this.currentRecordingSec += 1
          if (this.currentRecordingSec === this.seconds) {
            this.stopRecording()
            this.recordingCompleted = true
            clearInterval(this.recordingIntervalId)
            this.recordingIntervalId = null
          }
        }, 1000)
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    },

    // videoEnded () {
    //   this.playingVideo = false
    // },
    // playRecording () {
    //   this.$refs.videoElement.muted = false
    //   this.playingVideo = true
    //   const blob = new Blob(this.recordedChunks, { type: 'video/mp4' })
    //   const videoUrl = URL.createObjectURL(blob)
    //   this.$refs.videoElement.src = videoUrl
    //   this.$refs.videoElement.load()
    //   const promise = this.$refs.videoElement.play()
    //   if (promise !== undefined) {
    //     promise.then(() => {
    //       console.log('Autoplay started!')
    //       // Autoplay started!
    //     }).catch(error => {
    //       // Autoplay was prevented.
    //       // Show a "Play" button so that user can start playback.
    //       console.error('Error playing video:', error)
    //       this.playingVideo = false
    //       this.videoElement.muted = true
    //     })
    //   }
    // },

    // startNewRecording () {
    //   this.showRecorder = true
    //   this.showReplayButton = false
    //   this.checkPermissionAndInitializeRecorder()
    //   this.startCountdown()
    // },

    cancelRecording () {
      this.$emit('cancel')
    },

    stopRecording () {
      if (this.recordingIntervalId) clearInterval(this.recordingIntervalId)
      this.mediaRecorder.stop()

      this.$emit('save', this.recordedChunks, this.currentRecordingSec)
      this.showRecordingProgress = false
      this.currentRecordingSec = 0
      this.countdownValue = 5
    },
    startCountdown () {
      this.showInterval = true
      const interval = setInterval(() => {
        if (this.countdownValue === 1) {
          this.startRecording()
          clearInterval(interval)
        }
        this.countdownValue = this.countdownValue - 1
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
