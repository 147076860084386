<template>
  <div
    v-if="activeWidget"
    class=" tw-h-full tw-flex tw-flex-col tw-mt-16 tw-justify-center tw-items-center tw-my-auto tw-py-3 tw-px-2 tw-w-full tw-rounded-3xl tw-relative widget-container"
  >
    <div
      class="tw-h-full tw-min-h-[400px] tw-p-5 tw-rounded-3xl tw-relative tw-my-1"
      
      :class="{
        'tw-w-full':activeWidget
      }"
      
      style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);"
      :style="containerStyle"
    >
      <div
        class="tw-absolute tw-m-auto tw-left-0 tw-right-0 tw-flex tw-justify-center -tw-top-20"
        v-if="survey.introVideoMp4Url || survey.introVideoUrl"
      >
        <span
          class="tw-absolute tw-z-20 tw-h-[130px] tw-w-[130px] tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white tw-bg-black/20 tw-cursor-pointer"
          v-if="!videoPlaying"
          @click="playVideo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="tw-h-10 tw-w-10"
          >
            <path
              fill-rule="evenodd"
              d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <video
        :style="
                !survey.isUploadVideo ? 'transform:scaleX(-1)' : ''
              "
          @play="handleVideoPlay"
          @ended="handleVideoEnd"
          ref="videoPlayer"
          autoplay
          playsinline
          webkit-playsinline
          class="tw-rounded-full tw-z-10 tw-w-[130px]  tw-object-cover tw-h-[130px]"
        >
          <source :src="surveyIntroVideo" type="video/mp4" />
         {{ $t("noVideoSupport") }}
        </video>
        <!-- <img :src="PersonImagePath" width="200"> -->
      </div>
      <!-- <span class="tw-absolute tw-right-3 tw-top-3 tw-bg-[#F8F8F8] tw-cursor-pointer tw-h-[25px] tw-w-[25px] tw-flex tw-justify-center tw-items-center tw-rounded-full">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_118_1961)">
                      <path
                        d="M8.54266 7.51546L13.1884 2.52368C13.6027 2.07723 13.6027 1.35079 13.1884 0.904345C12.7857 0.471901 12.0831 0.471901 11.6804 0.904345L7.03319 5.89612L2.31935 0.834345C1.9036 0.387901 1.22853 0.387901 0.811329 0.834345C0.395573 1.28079 0.395573 2.00723 0.811329 2.45368L5.52517 7.51701L0.844648 12.5461C0.428891 12.9926 0.428891 13.719 0.844648 14.1655C1.04601 14.3817 1.314 14.4999 1.59793 14.4999C1.88187 14.4999 2.14986 14.3817 2.35122 14.1655L7.03319 9.13634L11.6789 14.125C11.8803 14.3412 12.1483 14.4595 12.4322 14.4595C12.7162 14.4595 12.9842 14.3412 13.187 14.125C13.6013 13.6786 13.6013 12.9521 13.187 12.5057L8.54121 7.51701L8.54266 7.51546Z"
                        fill="#979797"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_118_1961">
                        <rect
                          width="13"
                          height="14"
                          fill="white"
                          transform="translate(0.5 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                </span> -->
      <div
        class="tw-flex tw-flex-col tw-h-full tw-justify-center tw-items-center"
      >
        <p
          class="tw-font-semibold tw-w-full tw-text-xl tw-py-2 tw-text-center"
          :style="headerStyle"
        >
          {{ survey.headline || welcomeMessage }}
        </p>
        <div class="tw-w-full">
          <st-input
            v-if="!survey.anonymous"
            :id="email"
            v-model.trim="email"
            :label="inputLabel"
            :variant="inputVariants"
            @blur="handleInputFocusOut"
            @focus="handleInputFocus"
            @input="checkIfInputIsValid"
          />
        </div>
        <button
          class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-px-4 tw-py-3 tw-mt-4 tw-rounded-md  tw-w-full "
          :style="buttonStyle"
          :disabled="!isEmailValid"
          @click="handleContinue"
        >
          <span class="tw-flex tw-items-center">Start now</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="st-participant-invite-page tw-relative">
      <div
        v-if="survey.pageImageUrl"
        class="edit-cover-photo__photo-and-button st-participant-invite-page__cover-photo tw-mt-[72px] sm:-tw-mt-4"
        @focus="coverPhotoHovered = true"
        @focusout="coverPhotoHovered = false"
        @mouseleave="coverPhotoHovered = false"
        @mouseover="coverPhotoHovered = true"
      >
        <div
          :class="{
            '!tw-bg-contain ': true,
            'edit-cover-photo__photo': true,
            'edit-cover-photo__default': !survey.pageImageUrl,
            'edit-cover-photo__photo--hovered': coverPhotoHovered,
          }"
          :style="surveyCoverPhotoStyle"
        />
      </div>
      <div
        v-else-if="survey.account && survey.account.logoUrl"
        class="tw-top-24 sm:tw-top-10 tw-left-0 tw-absolute tw-flex tw-justify-center tw-w-full"
      >
        <img
          :src="survey.account.logoUrl"
          class="tw-w-auto tw-h-16 tw-mx-auto tw-block"
        />
      </div>
      <div
        class="bx--grid st-participant-invite-page__grid"
        :class="{
          'tw-mt-[72px]':
            !survey.pageImageUrl && survey.account && survey.account.logoUrl,
        }"
      >
        <div class="bx--row st-participant-invite-page__row">
          <div
            :class="{
              'st-participant-invite-page__information-and-button': true,
              'st-participant-invite-page__information-and-button--no-cover-photo': !survey.pageImageUrl,
            }"
          >
            <!-- <div
         key="company-logo"
         class="edit-company-logo__company-logo edit-company-logo--participant-invite-page"
         :style="companyLogoStyle"
       /> -->
            <h1 class="st-participant-invite-page__header">
              {{ survey.headline || $t('welcome') }}
            </h1>
            <div
              class="tw-w-full tw-max-w-[550px] tw-rounded-md tw-overflow-hidden tw-relative"
              v-if="survey.introVideoMp4Url || survey.introVideoUrl"
            >
              <span
                class="tw-absolute tw-z-20 tw-w-full tw-max-w-[550px] tw-h-[200px] tw-flex tw-justify-center tw-items-center tw-text-white tw-bg-black/50 tw-cursor-pointer"
                v-if="!videoPlaying"
                @click="playVideo"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="tw-h-10 tw-w-10"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <video
              :style="
                !survey.isUploadVideo ? 'transform:scaleX(-1)' : ''
              "
                @play="handleVideoPlay"
                @ended="handleVideoEnd"
                ref="videoPlayer"
                autoplay
                playsinline
                webkit-playsinline
                class="tw-z-10 tw-w-full  tw-object-cover tw-h-[200px]"
              >
                <source :src="surveyIntroVideo" type="video/mp4" />
            {{ 
              $t("noVideoSupport")
            }}
              </video>
              <!-- <img :src="PersonImagePath" width="200"> -->
            </div>

            <!-- || -->
                  <!-- "Thanks for being part of this amazing experience. You are about to take a survey, please provide us your email address to continue." -->
            <pre
              class="pre-tag st-participant-invite-page__instructions flex justify-center"
              v-else-if="!survey.anonymous"
              >{{
                survey.greeting
              }}
          </pre
            >
            <pre
              class="pre-tag st-participant-invite-page__instructions flex justify-center"
              v-else-if="survey.anonymous"
              >{{
                survey.greeting ||
                $t('surveyWelcome')
              }}
          </pre
            >
            <template v-if="isWdm && participant.email">
              <div class="st-participant-invite-page__set-button-full-width">
                <st-button
                  class="st-participant-invite-page__continue-button"
                  :caption="$t('continue')"
                  icon-name="arrow"
                  :underline="false"
                  @click="handleWdmContinue"
                />
              </div>
            </template>
            <template v-else>
              <!-- <st-tooltip
           class="st-participant__invalid-email-tooltip"
           :always-visible="showEmailError"
           arrow-class="st-participant__invalid-email-tooltip-arrow"
           direction="bottom"
           :is-active="showEmailError"
           :max-width="isDesktop ? 411 : 270"
           message-class="st-participant__invalid-email-tooltip-message"
           tip="Make sure your email has a @ and it ends in a valid email server"
         > -->

              <div class="mb-15">
                <p class="tw-text-sm">
                  {{
                    survey.anonymous && survey.collectEmail
                      ? survey.collectEmailMessage
                      : ""
                  }}
                </p>
                <st-input
                  v-if="
                    !survey.anonymous ||
                      (survey.anonymous && survey.collectEmail)
                  "
                  :id="email"
                  v-model.trim="email"
                  :label="inputLabel"
                  :variant="inputVariants"
                  @blur="handleInputFocusOut"
                  @focus="handleInputFocus"
                  @input="checkIfInputIsValid"
                />
                <!-- <st-spinner v-if="survey.anonymous" /> -->
                <!-- </st-tooltip> -->
                <p
                  v-if="survey.anonymous && !survey.collectEmail"
                  class="st-participant-invite-page__anon-text"
                >
                 {{ $t("anonymousSurvey") }}
                </p>
                <!-- v-if="!survey.anonymous" -->
                <div class="st-participant-segments__footer-group w-full">
                  <div class="st-participant-segments__continue-row">
                    <div
                      class="st-participant-invite-page__set-button-full-width w-full place-end-desktop"
                    >
                      <!-- :variant="['secondary']" -->
                      <st-button
                        class="st-participant-invite-page__continue-button mt-0-important w-full"
                        :caption="$t('continue')"
                        :disabled="!isEmailValid"
                        :underline="false"
                        @click="handleContinue"
                      />
                      <div
                        v-if="survey.anonymous && survey.collectEmail"
                        @click="handleSkip"
                        class="tw-mt-2 tw-flex tw-justify-center tw-cursor-pointer"
                      >
                        <span class="tw-text-primary tw-text-sm">
                          {{ $t("skipStep") }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="flex justify-center">
           <img
           :src="imagePath"
           alt="My Image"
           >
         </div>   -->
                </div>
              </div>
            </template>
            <!-- <p class="st-participant__terms-text">
         By clicking continue you accept our <a
           class="st-participant__term-link"
           :href="`/terms`"
           target="_blank"
         >
           Terms.
         </a>
       </p> -->
          </div>
          <!-- <div
          v-if="showPoweredBy"
          :class="{
            'st-powered-by-spacetrics st-powered-by-spacetrics--participant-invite': true,
            'st-powered-by-spacetrics--participant-invite-preview':
              invitationPreview || responsePreview,
          }"
        >
       
          Powered by
          <icon-wrapper
            class="st-powered-by-spacetrics__icon"
            :actionable="false"
            icon-name="spacetrics"
          />
          AskHumans
       
        </div>  -->
        </div>
      </div>
      <unsupported-browser v-if="survey.hasVoiceRecording" />
    </div>
  </div>
</template>

<script>
import PersonImage from "../../../../assets/images/microphone_person.png";
import { companyLogoStyle, isDesktop } from "../../../mixins";
import { AnonymousEmailGenerator } from "../../../utilities/data_utilities";
import { isValidEmail } from "../../../utilities/validation_utilities";
import {
  IconWrapper,
  StButton,
  StInput,
  StSpinner,
  StTooltip,
} from "../../common";
import UnsupportedBrowser from "../../participant/unsupported-browser";
import logo from "./askhumans-logo-black.png";
import posthog from "posthog-js";

export default {
  components: {
    IconWrapper,
    StButton,
    StInput,
    StTooltip,
    UnsupportedBrowser,
    StSpinner,
  },
  mixins: [companyLogoStyle, isDesktop],
  props: {
    invitationPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    participant: {
      type: Object,
      required: false,
      default: function() {
        return {};
      },
    },
    responsePreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    survey: {
      type: Object,
      required: true,
    },
    isWdm: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeWidget: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: function() {
    return {
      coverPhotoHovered: false,
      email: "",
      showEmailError: false,
      showPoweredBy: true,
      surveyResponses: this.survey.responses,
      responsesLimit: this.survey?.allowResponses,
      planName: this.survey.account?.plan?.title,
      imagePath: logo,
      PersonImagePath: PersonImage,
      videoPlaying: false,
     
    };
  },
  computed: {
    companyLogo() {
      return this.survey.logoUrl;
    },
    
    surveyIntroVideo() {
      return this.survey.introVideoMp4Url ? this.survey.introVideoMp4Url : this.survey.introVideoUrl;
    },
    containerStyle() {
      if (this.survey.widgetConfig) {
        return { background: this.survey.widgetConfig.background_color };
      } else {
        return {};
      }
    },
    headerStyle() {
      if (this.survey.widgetConfig) {
        return { color: this.survey.widgetConfig.text_color };
      } else {
        return {};
      }
    },
    buttonStyle() {
      if (this.survey.widgetConfig) {
        return {
          background: this.survey.widgetConfig.primary_button_background_color,
          color: this.survey.widgetConfig.primary_button_text_color,
        };
      } else {
        return {};
      }
    },
    isEmailValid() {
      if (this.survey.anonymous) {
        return true;
      }
      
      return isValidEmail(this.email);
    },
    inputLabel() {
      return this.showEmailError ? this.$t("invalidEmail") : this.$t("enterEmail");
    },
    inputVariants() {
      return this.showEmailError
      ? ["fullBorder", "largeFieldLabel", "persistLabelAbove", "invalid"]
        : ["fullBorder", "largeFieldLabel", "persistLabelAbove"];
      },
      showContinueButton() {
        if (this.isDesktop) {
          // "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY2s9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0d02f4c480d8b443c19fc75f7ef4684e20079bba/blob"
          return true;
        }
        if (!this.isDesktop && this.email) {
          return true;
        }
        return false;
      },
      surveyCoverPhotoStyle() {
        return this.survey.pageImageUrl
        ? { "background-image": `url('${this.survey.pageImageUrl}')` }
        : {};
      },
    },
    beforeMount () {
    
    this.changeLanguage(this.survey.language)
  },
  mounted() {
    
    this.$store.commit("toggleFullSpinner", true);
    setTimeout(() => {
      if (this.activeWidget) {
        this.removeClassesOfWidget();
      }
    }, 100);
    setTimeout(() => {
      this.getResponsesInCurrentMonth();
    }, 150);
    // setTimeout(()=>{
      //   this.$refs.videoPlayer.mute = true
    //   this.playVideo()
    // },2000)
    if (this.invitationPreview) {
      return;
    }
    // send anonymous email
    // setTimeout(() => {
      //   if (this.survey.anonymous) this.handleContinue();
      // }, 100);
      // this.email = this.participant.email;
      // this.$store.commit("hideSidebar", true);
      // sendNameEmail(this.participant.fullName, this.email);
      
      // // plan upgrade
      // const currentMonth = moment().format("MMMM");
      // const responsesCount = (this.surveyResponses || []).filter((response) => {
        //   const responseMonth = moment(response.createdAt).format("MMMM");
        //   return response.email && responseMonth === currentMonth;
        // }).length;
        
        // console.log("responsesCount", responsesCount);
        // console.log("responsesLimit", this.responsesLimit);
        
        // if (responsesCount >= this.responsesLimit) {
          //   window.location.href = "/quota_exceeded";
          // }
        },
        methods: {
          changeLanguage(lang) {
          this.$i18n.locale = lang;
        },
          removeClassesOfWidget() {
            const elements = document.getElementsByClassName("widget-container");

            Array.from(elements).forEach((element) => {
              element.style.background = "transparent";
              let parent = element.parentElement;
              while (parent) {
                parent.style.background = "transparent";
                parent = parent.parentElement;
              }
            });
          },
          handleSkip() {
      if (!this.invitationPreview && !this.responsePreview) {
        let email = this.email;
        email = AnonymousEmailGenerator();
        
        let url = `/study/${this.survey.id}/response`;
        this.$axios
        .request({
          method: "post",
          url: url,
          data: {
            participant: { email: email },
            survey: this.survey,
          },
        })
          .then((res) => {
            if (res.data.success) {
              if (this.isWdm) {
                this.handleWdmContinue();
              } else {
                window.location.href = `/study/${this.survey.id}?participant_token=${res.data.participant_token}&active_widget=${this.activeWidget}`;
                // window.location.href = `/study/${this.survey.id}?participant_token=${res.data.participant_token}`;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.responsePreview || this.invitationPreview) {
        if (this.isWdm) {
          this.handleWdmContinue();
        } else {
          window.location.href = `/study/${this.survey.id}/segments/preview`;
        }
      } else {
        this.$emit("closeModal", false);
      }
    },
    playVideo() {
      this.$refs.videoPlayer.play();
      this.videoPlaying = true;
    },

    handleVideoPlay() {
      // Video started playing

      this.videoPlaying = true;
    },
    handleVideoEnd() {
      // Video ended playing
      this.videoPlaying = false;
    },
    checkIfInputIsValid() {
      if (this.showEmailError && this.isEmailValid) {
        this.showEmailError = false;
      }
    },
    handleInputFocus() {
      if (!this.isDesktop) {
        this.showPoweredBy = false;
      }
    },
    handleInputFocusOut() {
      this.showEmailError = !this.isEmailValid;
      if (this.email === "") {
        this.showEmailError = false;
      }
      if (!this.isDesktop) {
        this.showPoweredBy = true;
      }
    },
    handleContinue() {
      if (!this.invitationPreview && !this.responsePreview) {
        let email = this.email;
        if (this.survey.anonymous && !this.survey.collectEmail) {
          email = AnonymousEmailGenerator();
        } else {
          email = this.email;
        }

        if (!isValidEmail(email)) {
          this.showEmailError = true;
          return;
        }
        let url = `/study/${this.survey.id}/response`;
        this.$axios
          .request({
            method: "post",
            url: url,
            data: {
              participant: { email: email },
              survey: this.survey,
            },
          })
          .then((res) => {
            if (res.data.success) {
              if (this.isWdm) {
                this.handleWdmContinue();
              } else {
                posthog.identify(res.data.participant_token, { email });
                window.location.href = `/study/${this.survey.id}?participant_token=${res.data.participant_token}&active_widget=${this.activeWidget}`;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.responsePreview || this.invitationPreview) {
        if (this.isWdm) {
          this.handleWdmContinue();
        } else {
          window.location.href = `/study/${this.survey.id}/segments/preview`;
        }
      } else {
        this.$emit("closeModal", false);
      }
    },

    getResponsesInCurrentMonth() {
      this.$axios
        .request({
          method: "get",
          url: `/study/${this.survey.id}/total_responses_current_month`,
        })
        .then(({ data }) => {
          const {
            total_responses: responsesInCurrentMonth,
            override_limit: overrideLimit,
          } = data;

          if (overrideLimit && responsesInCurrentMonth >= overrideLimit) {
            window.location.href = "/quota_exceeded";
          } else if (responsesInCurrentMonth >= this.responsesLimit) {
            window.location.href = "/quota_exceeded";
          } else {
            this.$store.commit("toggleFullSpinner", false);
          }

          // console.log(data)
        })
        .catch((err) => {
          this.$store.commit("toggleFullSpinner", false);
          console.log(err);
        });
    },

    handleWdmContinue() {
      if (this.invitationPreview) {
        return;
      }

      // go to first question
      let url = `/questions/${this.survey.firstQuestionId}`;
      if (this.responsePreview) {
        url += "/preview";
      }
      window.location.href = url;
    },
  },
};
</script>
<style scoped>
@media (min-width: 768px) {
  .place-end-desktop {
    display: flex;
    /* height: 250px !important; */
    margin-top: 100px;
    justify-content: end;
  }
}
.pre-tag {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-word-break: break-all;
  hyphens: auto;
}
</style>
