<template>
  <div
    v-if="showAnswer"
    class="tw-flex tw-flex-col tw-justify-between tw-h-full"
  >
    <div
      :class="{
        'tw-max-h-[90%]': !selectedAnswer.history.is_saved,
        'tw-h-[95%]': selectedAnswer.history.is_saved,
      }"
      class="scrollbar-thumb-dark-gray-400 tw-h-full tw-overflow-auto scrollbar-hover-black scrollbar-thin scrollbar-track-gray-200"
    >
      <div>
        <div class="tw-bg-territory-dark tw-p-4">
          <div class="tw-flex tw-items-center tw-cursor-pointer">
            <div
              class="tw-flex tw-justify-center tw-items-center tw-bg-white tw-rounded-full tw-h-10 tw-shrink-0 tw-w-10"
              @click="showAnswer = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </div>
            <p class="tw-pr-12 tw-w-full tw-text-center tw-text-white">
              Question
            </p>
          </div>
          <p
            class="tw-my-5 tw-font-semibold tw-text-2xl tw-text-center tw-text-white"
          >
            {{ selectedAnswer.history.question }}
          </p>
        </div>
      </div>

      <div class="tw-px-5">
        <text-divider
          text="Answer"
          class="tw-my-5 tw-font-semibold"
        />
        <div class="tw-my-5 tw-text-base tw-leading-6">
          <p>
            {{ selectedAnswer.history.answer }}
          </p>
        </div>
        <text-divider
          text="Sources"
          class="tw-mt-4 tw-font-semibold"
        />
        <div
          v-for="(source, index) in selectedAnswer.history.sources"
          :key="`${index}-source`"
          class="tw-border-white-two tw-mt-5 tw-p-5 tw-border tw-border-solid tw-rounded-xl"
        >
          <div class="tw-border-white-two tw-py-3 tw-border-b tw-border-solid">
            <p class="tw-flex tw-space-x-5">
              <span class="tw-font-semibold tw-text-black-one">
                {{ source.participantName }}
              </span>
              <span class="tw-text-black-three">
                {{ getReadableDate(source) }}
              </span>
            </p>
            <p class="tw-mt-2">
              {{ source.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-right-0 tw-bottom-5 tw-flex tw-items-end tw-space-x-3 tw-bg-white tw-px-5"
    >
      <ah-button
        class="tw-w-full"
        type="outlined"
        size="large"
        @click="showDiscardScreen"
      >
        Delete answer
      </ah-button>
      <!-- <ah-button
        v-if="!selectedAnswer.history.is_saved"
        class="tw-w-full"
        size="large"
        @click="saveAnswerHandler"
      >
        Save
      </ah-button> -->
    </div>
  </div>
  <div
    v-else-if="showDiscardModal && !showAnswer"
    class="tw-flex tw-justify-center tw-items-center tw-px-5 tw-h-full"
  >
    <div class="tw-bg-white tw-w-full">
      <p class="tw-text-2xl tw-text-center">
        Are you sure you want to discard this question?
      </p>
      <div class="tw-my-3">
        <ah-button
          class="tw-w-full"
          size="large"
          type="outlined"
          @click="showDiscardHandler"
        >
          Discard
        </ah-button>
      </div>
      <div
        class="tw-flex tw-justify-center tw-my-3 tw-py-2 tw-w-full tw-cursor-pointer"
      >
        <span
          class="tw-block tw-mx-0 tw-w-fit"
          @click="goBackFromDiscard"
        >
          Go back
        </span>
      </div>
    </div>
  </div>
  <div
    v-else
    class="tw-px-5 tw-pb-5"
  >
    <ah-small-loader
      v-if="loading"
      :loading="loading"
    />
    <template v-else>
      <p
        v-if=" !answersList.length "
        class="tw-my-10 tw-py-1 tw-text-center"
      >
        No answers found!
      </p>
      <p
        v-if="filterAnswers('status', 'Processing').length"
        class="tw-flex tw-items-center tw-space-x-2 tw-my-3 tw-py-1"
      >
        <span
          class="tw-border-[1px] tw-border-white tw-bg-warning tw-border-solid tw-rounded-full tw-h-[15px] tw-ring-2 tw-ring-warning/40 tw-w-[15px]"
        />
        <span class="tw-black-three">
          Processing
        </span>
      </p>
      <div
        v-for="history in filterAnswers('status', 'Processing')"
        :key="`${history.history.id}_history_processing`"
        class="tw-flex tw-justify-between tw-items-center tw-space-x-4 tw-border-white-two tw-bg-white-one tw-my-3 tw-p-4 tw-border tw-border-solid tw-rounded-xl tw-cursor-not-allowed"
      >
        <div>
          <p>
            {{ history.surveys.length }} studies /
            {{ history.history.question_type }}
          </p>
          <p class="tw-font-semibold tw-text-lg">
            {{ history.history.question }}
          </p>
        </div>
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.99996 14.0481C5.99996 14.988 6.76194 15.75 7.70189 15.75C8.64183 15.75 9.40381 14.988 9.40381 14.0481C9.40381 13.1081 8.64183 12.3462 7.70189 12.3462C6.76194 12.3462 5.99996 13.1081 5.99996 14.0481ZM5.99996 8.37502C5.99996 9.31496 6.76194 10.0769 7.70189 10.0769C8.64183 10.0769 9.40381 9.31496 9.40381 8.37502C9.40381 7.43507 8.64183 6.67309 7.70189 6.67309C6.76194 6.67309 5.99996 7.43507 5.99996 8.37502ZM5.99996 2.70191C5.99996 3.64186 6.76194 4.40384 7.70189 4.40384C8.64183 4.40384 9.40381 3.64186 9.40381 2.70191C9.40381 1.76197 8.64183 0.99999 7.70189 0.99999C6.76194 0.99999 5.99996 1.76197 5.99996 2.70191Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
      <p
        v-if="filterAnswers('status', 'New').length"
        class="tw-flex tw-items-center tw-space-x-2 tw-mt-5 tw-mb-3"
      >
        <span
          class="tw-border-[1px] tw-border-white tw-bg-very-positive tw-border-solid tw-rounded-full tw-h-[15px] tw-ring-2 tw-ring-very-positive/40 tw-w-[15px]"
        />
        <span class="tw-black-three">
          New results
        </span>
      </p>
      <div
        v-for="history in filterAnswers('status', 'New')"
        :key="`${history.history.id}_history_new`"
        class="tw-flex tw-justify-between tw-items-center tw-space-x-4 tw-border-white-two tw-bg-white-one tw-my-3 tw-p-4 tw-border tw-border-solid tw-rounded-xl tw-cursor-pointer"
        @click="selectAnswerToShow(history.history.id)"
      >
        <div>
          <p>
            {{ history.surveys.length }} studies /
            {{ history.history.question_type }}
          </p>
          <p class="tw-font-semibold tw-text-lg">
            {{ history.history.question }}
          </p>
        </div>
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.99996 14.0481C5.99996 14.988 6.76194 15.75 7.70189 15.75C8.64183 15.75 9.40381 14.988 9.40381 14.0481C9.40381 13.1081 8.64183 12.3462 7.70189 12.3462C6.76194 12.3462 5.99996 13.1081 5.99996 14.0481ZM5.99996 8.37502C5.99996 9.31496 6.76194 10.0769 7.70189 10.0769C8.64183 10.0769 9.40381 9.31496 9.40381 8.37502C9.40381 7.43507 8.64183 6.67309 7.70189 6.67309C6.76194 6.67309 5.99996 7.43507 5.99996 8.37502ZM5.99996 2.70191C5.99996 3.64186 6.76194 4.40384 7.70189 4.40384C8.64183 4.40384 9.40381 3.64186 9.40381 2.70191C9.40381 1.76197 8.64183 0.99999 7.70189 0.99999C6.76194 0.99999 5.99996 1.76197 5.99996 2.70191Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
      <p
        v-if="filterAnswers('not sure', 'saved').length"
        class="tw-border-white-two tw-mt-5 tw-pt-4 tw-border-t tw-border-solid"
      >
        Showing {{ filterAnswers('not sure', 'saved').length }} results
      </p>
      <div
        v-for="history in filterAnswers('not sure', 'saved')"
        :key="`${history.history.id}_history_saved`"
        class="tw-flex tw-justify-between tw-items-center tw-space-x-4 tw-border-white-two tw-my-3 tw-p-4 tw-border tw-border-solid tw-rounded-xl tw-cursor-pointer"
        @click="selectAnswerToShow(history.history.id)"
      >
        <div>
          <p>
            {{ history.surveys.length }} studies /
            {{ history.history.question_type }}
          </p>
          <p class="tw-font-semibold tw-text-lg">
            {{ history.history.question }}
          </p>
        </div>
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.99996 14.0481C5.99996 14.988 6.76194 15.75 7.70189 15.75C8.64183 15.75 9.40381 14.988 9.40381 14.0481C9.40381 13.1081 8.64183 12.3462 7.70189 12.3462C6.76194 12.3462 5.99996 13.1081 5.99996 14.0481ZM5.99996 8.37502C5.99996 9.31496 6.76194 10.0769 7.70189 10.0769C8.64183 10.0769 9.40381 9.31496 9.40381 8.37502C9.40381 7.43507 8.64183 6.67309 7.70189 6.67309C6.76194 6.67309 5.99996 7.43507 5.99996 8.37502ZM5.99996 2.70191C5.99996 3.64186 6.76194 4.40384 7.70189 4.40384C8.64183 4.40384 9.40381 3.64186 9.40381 2.70191C9.40381 1.76197 8.64183 0.99999 7.70189 0.99999C6.76194 0.99999 5.99996 1.76197 5.99996 2.70191Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AhButton from '../ah-button.vue'
import TextDivider from '../text-divider.vue'
import AhSmallLoader from '../ah-small-loader.vue'
import moment from 'moment'

export default {
  components: {
    AhButton,
    TextDivider,
    AhSmallLoader
  },
  props: {
    showToastMessage: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      showAnswer: false,
      showDiscardModal: false,
      answersList: [],
      selectedAnswer: null,
      loading: false
    }
  },
  computed: {},
  mounted () {
    this.getAnswerHistories()
  },

  methods: {
    goBackFromDiscard () {
      this.showDiscardModal = false
      this.showAnswer = true
    },
    getAnswerHistories () {
      this.loading = true
      this.$axios
        .get('/suggested_answer_historys/show')
        .then((response) => {
          this.answersList = response.data.result
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    showDiscardScreen () {
      this.showDiscardModal = true
      this.showAnswer = false
    },
    showDiscardHandler () {
      this.$axios
        .delete(`/suggested_answer_historys/${this.selectedAnswer.history.id}`)
        .then((response) => {
          console.log(response)
          this.showDiscardModal = false
          this.showAnswer = false
          this.$emit('showToastMessage', 'removed')
          this.getAnswerHistories()
        })
        .catch((error) => {
          console.log(error)
        })
      this.showDiscardModal = true
      this.showAnswer = false
    },
    filterAnswers (type, value) {
      if (type === 'status') {
        return this.answersList.filter(
          (history) =>
            history.history.status === value && !history.history.is_saved
        )
      } else {
        return this.answersList.filter((history) => history.history.is_saved)
      }
    },
    // getReadableDate (data) {
    //   if (!data.createdAt) return
    //   const date = moment(data.createdAt) // No need to specify format if the input is in ISO 8601 format
    //   const formattedDate = date.format('MMM DD, YYYY')
    //   return formattedDate
    // },
    getReadableDate (data) {
      if (!data.createdAt) return

      const dateString = data.createdAt.replace(' EDT', '') // Remove 'EDT' to make it compatible with Safari
      const date = new Date(dateString)

      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

      const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`

      return formattedDate
    },
    selectAnswerToShow (historyId) {
      this.selectedAnswer = this.answersList.find(
        (history) => history.history.id === historyId
      )
      this.showAnswer = true
    },
    saveAnswerHandler () {
      this.$axios
        .patch(`/suggested_answer_historys/${this.selectedAnswer.history.id}`, {
          is_saved: true,
          status: 'Saved'
        })
        .then((response) => {
          this.showAnswer = false
          this.$emit('showToastMessage', 'saved')
          this.getAnswerHistories()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
