<template>
  <div
    v-if="!isStudy && showWidgetQuestionOptions"
  >
    <template>
      <!-- <div class="-tw-top-28 tw-right-0 tw-left-0 tw-absolute tw-flex tw-justify-center tw-m-auto">
        <img
          :src="PersonImagePath"
          width="200"
        >
      </div>
      <span class="tw-top-3 tw-right-3 tw-absolute tw-flex tw-justify-center tw-items-center tw-bg-[#F8F8F8] tw-rounded-full tw-h-[25px] tw-w-[25px] tw-cursor-pointer">
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_118_1961)">
            <path
              d="M8.54266 7.51546L13.1884 2.52368C13.6027 2.07723 13.6027 1.35079 13.1884 0.904345C12.7857 0.471901 12.0831 0.471901 11.6804 0.904345L7.03319 5.89612L2.31935 0.834345C1.9036 0.387901 1.22853 0.387901 0.811329 0.834345C0.395573 1.28079 0.395573 2.00723 0.811329 2.45368L5.52517 7.51701L0.844648 12.5461C0.428891 12.9926 0.428891 13.719 0.844648 14.1655C1.04601 14.3817 1.314 14.4999 1.59793 14.4999C1.88187 14.4999 2.14986 14.3817 2.35122 14.1655L7.03319 9.13634L11.6789 14.125C11.8803 14.3412 12.1483 14.4595 12.4322 14.4595C12.7162 14.4595 12.9842 14.3412 13.187 14.125C13.6013 13.6786 13.6013 12.9521 13.187 12.5057L8.54121 7.51701L8.54266 7.51546Z"
              fill="#979797"
            />
          </g>
          <defs>
            <clipPath id="clip0_118_1961">
              <rect
                width="13"
                height="14"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>

      </span> -->
      <div

        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full"
      >
        <!-- <p
          class="tw-flex tw-flex-col tw-text-center"
          :style="textStyle"
        >
          <span class="tw-text-center tw-text-gray-600 tw-text-sm">
            Question
          </span>
          <span class="tw-font-semibold tw-text-xl">
            {{ description }}
          </span>
        </p> -->
        <!-- <div class="tw-border-gray-100 tw-my-3 tw-border-b tw-border-solid tw-w-full" />

        <button
          v-if="askForExplanation==='let_user_decide' || askForExplanation==='voice_recording'"
          class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-mt-4 tw-px-4 tw-py-3 tw-rounded-md tw-w-full"
          :style="buttonStyle"
          @click="askForAudioPermission"
        >
          <span
            class="tw-flex tw-items-center"
          >Record answer</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
        <button
          v-if="askForExplanation==='let_user_decide' || askForExplanation==='text'"
          class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-mt-2 tw-px-4 tw-py-3 tw-rounded-md tw-w-full"
          :style="buttonStyle"
          @click="typeYourAnswer"
        >
          <span
            class="tw-flex tw-items-center"
          >Type answer</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button> -->
      </div>
    </template>
  </div>
  <div
    v-else-if="showAudioInstructions && !optionForSurvey"
    class="st-opinion-metric__inner-wrapper"
  >
    <microphone-access
      v-if="showToast && errorMessage"
      class="st-opinion-metric__microphone-modal"
      :show-microphone-access="showToast && errorMessage !== ''"
      @closeMicrophoneAccess="showToast = false"
    />
    <instructions
      key="instructions"
      :error-message="errorMessage"
      :is-single-question-survey="false"
      :is-join-survey="isJoinSurvey"
      :has-media-recorder="true"
      :company-logo="activeQuestion.logoUrl"
      :audio-permission-granted="audioPermissionGranted"
      :spacetrics-logo="spacetricsLogo"
      :show-change-to-text-button="
        askForExplanation === 'let_user_decide' ? true : false
      "
      @ask-for-audio-permission="askForAudioPermission"
      @disableInstructions="handleDisableInstructions"
      @changeToTextResponse="changeToTextResponse"
    />
  </div>
  <div
    v-else
    :class="{
      'st-opinion-metric__container-set': true,
      'st-opinion-metric__container tw-h-full': isDesktop,
      'st-opinion-metric__container': !isStudy,
      'st-opinion-metric__container--preview': isSpecsDesktopPreview,
      'st-opinion-metric__container--multi-media-preview':
        isSpecsDesktopPreview && hasMultipleMedia,
    }"
  >
    <div class="st-opinion-metric__canvas-preview">
      <div
        class="st-opinion-metric__canvas-page"
        :class="{
          'tw-mt-12':account.logoUrl && isStudy
        }"
      >
        <div
          :class="{
            'tw-h-0': !isStudy,
            'st-opinion-metric__question-with-text-area': hasNoMedia,
            'st-opinion-metric__question-with-text-area--focused':
              !hasSingleMedia &&
              !hasExternalMedia &&
              textAreaFocused &&
              !isDesktop,
            'st-opinion-metric__question-with-text-area--preview':
              !hasSingleMedia && !hasExternalMedia && isSpecsDesktopPreview,
          }"
        >
          <div v-if="showFollowUpQuestion">
            <p
              class="tw-text-xl font-bold text-center st-opinion-metric__question"
              :class="{'!tw-text-xl': !isStudy}"
            >
              {{ followUpQuestionText }}
            </p>
          </div>

          <div
            v-if="!showFollowUpQuestion"
            ref="questionContainer"
            :class="{
              'st-opinion-metric__question-container': true,
              // 'st-opinion-metric__question-container--hide':
              //   textAreaFocused && hasSingleMedia && !isDesktop,
              'st-opinion-metric__question-container--no-media': hasNoMedia,
              'st-opinion-metric__question-container--no-media-survey':
                hasNoMedia && !isSpecsDesktopPreview,
              'st-opinion-metric__question-container--no-media-specs':
                hasNoMedia && isSpecsDesktopPreview,
              'st-opinion-metric__question-container--no-media--focus':
                !isPreview &&
                !hasSingleMedia &&
                textAreaFocused &&
                !hasExternalMedia &&
                !isDesktop,
              'st-opinion-metric__question-container--video':
                isMediaVideo || activeQuestion.externalVideoUrl,
              'tw-mt-12':account.logoUrl,
              'st-opinion-metric__question-container--overflow-description': overflowDescription,
            }"
          >
            <p
              v-if="hasMultipleMedia && !isDesktop"
              ref="question"
              v-sticky
              class="st-opinion-metric__question--multi-media"
            >
              {{ description }}
            </p>
            <p
              v-else-if="hasMultipleMedia && isDesktop && isResponseTypeText"
              ref="question"
              class="st-opinion-metric__question--multi-media-text-response"
            >
              {{ description }}
            </p>
            <p
              v-else-if=" isStudy &&
                !hasMultipleMedia || (hasMultipleMedia && isSpecsDesktopPreview)
              "
              ref="question"
              class="scrollbar-thumb-dark-gray-400 !tw-text-base max-height-550p scrollbar-hover-black scrollbar-thin scrollbar-track-gray-200 st-opinion-metric__question"
              :style="textStyle"
            >
              <span v-html="addStylesToContent(description)" />
            </p>
          </div>
          <div
            v-if="hasSingleMedia"
            :class="{
              'tw-hidden': !isStudy,
              'st-opinion-metric__image-canvas-box tw-mt-3': true,
              'st-opinion-metric__video-container': isMediaVideo,
              'st-opinion-metric__video-container--specifications':
                isMediaVideo && isSpecsDesktopPreview,
            }"
          >
            <video
              v-if="isMediaVideo"
              :key="questionId"
              controls
              playsinline
              webkit-playsinline
              class="st-opinion-metric__video"
            >
              <source
                :src="mediaUrl"
                :type="contentType"
              >
              Your browser does not support this video
            </video>
            <div
              v-else
              class="st-opinion-metric__image-canvas"
            >
              <div
                v-if="showExpanedPhoto"
                class="st-opinion-metric__image-container st-opinion-metric__image-container--expanded"
              />
              <div
                v-else
                :class="{
                  'st-opinion-metric__image-container': true,

                }"
              >
                <!-- <div
                v-if="isExpandPhotoIcon"
                ref="expandImageIcon"
                class="st-opinion-metric__expand-icon-container"
                @click="expandPhoto"
              >
                <icon-wrapper
                  class="st-opinion-metric__expand-icon"
                  icon-name="expand-image"
                />
              </div> -->
                <img
                  :src="mediaUrl"
                  :class="{
                    'st-opinion-metric__image st-opinion-metric__image-full': true,
                    'st-opinion-metric__image--clickable': !isSpecsDesktopPreview,
                  }"
                  @click="expandPhoto"
                >
              </div>
            </div>
          </div>
          <div
            v-if="

              (isResponseTypeText && hasNoMedia && !isStudy) ||
                (isResponseCustom && customResponseOption === 'text' && !isStudy) || (isResponseCustom && optionForSurvey === 'text' && !isStudy)
            "
            :class="{
              'tw-shadow-none tw-bg-transparent': !isStudy,
              'st-opinion-metric__text-area-container mb-20-important ':
                !isDesktopSurveyPreview && !isDesktopSurvey,
              'st-opinion-metric__text-area-container--focused': textAreaFocused,
              'st-opinion-metric__text-area-container--focused-desktop':
                (textAreaFocused && isDesktopSurveyPreview) ||
                (textAreaFocused && isDesktopSurvey),
              'st-opinion-metric__text-area-container--focused-specs':
                textAreaFocused && isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--next-button-visible':
                !isPreview && nextButtonTextArea && isStudy,
              'st-opinion-metric__text-area-container--next-button': nextButtonTextArea && isStudy,
              'st-opinion-metric__text-area-container--specifications-preview': isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--specifications-preview-next-question':
                isSpecsDesktopPreview && nextButtonTextArea,
              'st-opinion-metric__text-area-container--desktop':
                isDesktopSurveyPreview || isDesktopSurvey,
            }"
          >
            <div class="tw-relative tw-flex tw-justify-between tw-items-center tw-space-x-4 tw-py-5 tw-w-full" v-if="!showFollowUpQuestion">
              <textarea
                v-model="writtenResponse"
                placeholder="Type your answer"
                class="tw-bg-gray-200 tw-p-3 tw-border-none tw-rounded-lg tw-h-20 tw-resize-none tw-w-full"
                :class="{'!tw-border !tw-border-red-500 !tw-border-solid tw-placeholder-red-500': requiredTextError}"
              />

              <span
                class="-tw-right-2 tw-absolute tw-flex tw-justify-center tw-items-center tw-p-1 tw-rounded-full tw-h-6 tw-w-6 tw-cursor-pointer"
                :style="buttonStyle"
                @click="beforeSubmit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-h-3 tw-w-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </span>
            </div>
            <!-- <st-text-area
              v-if="!showFollowUpQuestion"
              v-model="writtenResponse"
              :class="{
                'st-opinion-metric__text-area': true,
                'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
                'st-opinion-metric__text-area--next-button-visible-specs':
                  isPreview && nextButtonTextArea && !isSurveyPreview,
              }"
              :button="textAreaFocused"
              label="Share your thoughts here"
              :label-size-class="
                (nextButtonTextArea && !isPreview) ||
                  (nextButtonTextArea && isSurveyPreview)
                  ? 'st-opinion-metric__text-area-label'
                  : 'st-opinion-metric__text-area-label--specifications'
              "
              placeholder="Tell us what you think"
              @focus="focusTextArea"
              @focusout="focusOutTextArea"
            /> -->
            <st-text-area
              v-if="showFollowUpQuestion"
              v-model="followUpAnswer"
              placeholder="Type your answer"
              :class="{
                'st-opinion-metric__text-area': true,
                'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
                'st-opinion-metric__text-area--next-button-visible-specs':
                  isPreview && nextButtonTextArea && !isSurveyPreview,
              }"
            />
            <st-button
                v-if="showFollowUpQuestion && !isStudy"
                class="st-button-secondary w-full st-opinion-metric__button-two st-opinion-metric__next-question-button"
                :caption="buttonCaption"
                :disabled="!followUpAnswer"
                icon-name="arrow"
                :underline="false"
                :variant="['primary']"
                :style="buttonStyle"
                @click="beforeSubmit"
              />
            <!-- :button="textAreaFocused"
            label="Share your thoughts here"
            :label-size-class="nextButtonTextArea && !isPreview || nextButtonTextArea && isSurveyPreview ? 'st-opinion-metric__text-area-label' : 'st-opinion-metric__text-area-label--specifications'"
            placeholder="Tell us what you think"
            @focus="focusTextArea"
            @focusout="focusOutTextArea" -->
            <!-- <st-tooltip
              v-if="nextButtonTextArea"
              class="st-opinion-metric__button-tooltip"
              tip="Preview only button"
              :is-active="isSpecificationsPreview"
            > -->
            <!-- <div class="place-end-desktop">
              <st-button
                class="mt-5 w-full st-button-secondary st-opinion-metric__button-two st-opinion-metric__next-question-button"
                :caption="buttonCaption"
                :disabled="!writtenResponse.length"
                icon-name="arrow"
                :underline="false"
                :variant="['primary']"
                @click="beforeSubmit"
              />
            </div> -->
            <!-- @click="submitQuestionAnswer" -->
            <!-- </st-tooltip> -->
          </div>

          <div
            v-else-if="
              (isResponseTypeText && hasNoMedia) ||
                (isResponseCustom && customResponseOption === 'text') || (isResponseCustom && optionForSurvey === 'text')
            "
            :class="{
              'st-opinion-metric__text-area-container mb-20-important ':
                !isDesktopSurveyPreview && !isDesktopSurvey,
              'st-opinion-metric__text-area-container--focused': textAreaFocused,
              'st-opinion-metric__text-area-container--focused-desktop':
                (textAreaFocused && isDesktopSurveyPreview) ||
                (textAreaFocused && isDesktopSurvey),
              'st-opinion-metric__text-area-container--focused-specs':
                textAreaFocused && isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--next-button-visible':
                !isPreview && nextButtonTextArea,
              'st-opinion-metric__text-area-container--next-button': nextButtonTextArea,
              'st-opinion-metric__text-area-container--specifications-preview': isSpecsDesktopPreview,
              'st-opinion-metric__text-area-container--specifications-preview-next-question':
                isSpecsDesktopPreview && nextButtonTextArea,
              'st-opinion-metric__text-area-container--desktop':
                isDesktopSurveyPreview || isDesktopSurvey,
            }"
          >
            <st-text-area
              v-if="!showFollowUpQuestion"
              v-model="writtenResponse"
              :class="{
                'st-opinion-metric__text-area': true,
                'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
                'st-opinion-metric__text-area--next-button-visible-specs':
                  isPreview && nextButtonTextArea && !isSurveyPreview,
              }"
              :button="textAreaFocused"
              :label='$t("shareYourThoughts")'
              :label-size-class="
                (nextButtonTextArea && !isPreview) ||
                  (nextButtonTextArea && isSurveyPreview)
                  ? 'st-opinion-metric__text-area-label'
                  : 'st-opinion-metric__text-area-label--specifications'
              "
              :placeholder='$t("tellUsWhatYouThink")'
              @focus="focusTextArea"
              @focusout="focusOutTextArea"
            />
            <st-text-area
              v-if="showFollowUpQuestion"
              v-model="followUpAnswer"
              placeholder="Type your answer"
              :class="{
                'st-opinion-metric__text-area': true,
                'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
                'st-opinion-metric__text-area--next-button-visible-specs':
                  isPreview && nextButtonTextArea && !isSurveyPreview,
              }"
            />
            
            <!-- :button="textAreaFocused"
            label="Share your thoughts here"
            :label-size-class="nextButtonTextArea && !isPreview || nextButtonTextArea && isSurveyPreview ? 'st-opinion-metric__text-area-label' : 'st-opinion-metric__text-area-label--specifications'"
            placeholder="Tell us what you think"
            @focus="focusTextArea"
            @focusout="focusOutTextArea" -->
            <!-- <st-tooltip
              v-if="nextButtonTextArea"
              class="st-opinion-metric__button-tooltip"
              tip="Preview only button"
              :is-active="isSpecificationsPreview"
            > -->
            <div class="place-end-desktop">
              <st-button
                class="mt-5 w-full st-button-secondary st-opinion-metric__button-two st-opinion-metric__next-question-button"
                :caption="buttonCaption"
                :disabled="!writtenResponse.length"
                icon-name="arrow"
                :underline="false"
                :variant="['primary']"
                @click="beforeSubmit"
                
              />
            </div>
            <p
              v-if="optional"
              class="tw-py-2 tw-text-center tw-text-primary tw-text-sm hover:tw-underline tw-cursor-pointer"
              @click="skipThisQuestion"
            >
              <!-- Skip this question -->
              {{ $t("skipThisQuestion") }}
            </p>
            <!-- @click="submitQuestionAnswer" -->
            <!-- </st-tooltip> -->
          </div>
        </div>
        <!-- REPOSITIONED MEDIA START -->

        <!-- REPOSITIONED MEDIA END -->

        <div
          v-if="activeQuestion.externalVideoUrl"
          :class="{
            'st-opinion-metric__video-container':
              activeQuestion.externalVideoUrl,
            'st-opinion-metric__video-container--specifications':
              activeQuestion.externalVideoUrl && isSpecsDesktopPreview,
          }"
        >
          <st-external-video :url="activeQuestion.externalVideoUrl" />
        </div>
      </div>
      <div
        v-if="hasMultipleMedia"
        :class="{
          'st-opinion-metric__zoom-icons': true,
          'st-opinion-metric__zoom-icons--sticky-active': isStickyQuestionActive,
          'st-opinion-metric__zoom-icons--desktop-preview': isDesktopSurveyPreview,
          'st-opinion-metric__zoom-icons--preview-maximize':
            zoomLevel > 100 && isDesktopSurveyPreview,
        }"
      >
        <div
          class="st-opinion-metric__zoom-icon-container"
          @click="handleZoomIn"
        >
          <icon-wrapper icon-name="plus" />
        </div>
        <div
          :class="{
            'st-opinion-metric__zoom-icon-container st-opinion-metric__zoom-icon-container--minus': true,
            'st-opinion-metric__zoom-icon-container--disabled':
              zoomLevel === 60,
          }"
          @click="handleZoomOut"
        >
          <icon-wrapper icon-name="minus" />
        </div>
      </div>
      <div
        v-if="hasMultipleMedia"
        ref="multiMediaImages"
        :class="{
          'st-opinion-metric__multi-media-images': zoomLevel > 100,
          'st-opinion-metric__multi-media-images--survey-preview':
            isDesktopSurveyPreview && zoomLevel > 100,
          'st-opinion-metric__multi-media-images--specs-preview':
            isSpecsDesktopPreview && zoomLevel > 100,
        }"
        @click="resetZoomLevel"
      >
        <div
          v-for="(multiMedia, index) in options"
          :key="index"
          ref="multiMedia"
          :class="{
            'st-opinion-metric__multi-media-container': true,
            'st-opinion-metric__multi-media-container--last':
              index == options.length - 1,
          }"
          :style="imageStyle"
        >
          <video
            v-if="isMultiMediaVideo(multiMedia)"
            :key="'multiMedia' + index"
            controls
            playsinline
            webkit-playsinline
            class="st-opinion-metric__multi-media"
            @loadeddata="setImageWidth(index)"
          >
            <source :src="multiMedia.mediaFullUrl">
            <source :src="multiMedia.mediaFullUrl">
            Your browser does not support this video
          </video>
          <img
            v-else
            ref="multiMediaImage"
            class="st-opinion-metric__multi-media"
            :src="multiMedia.mediaFullUrl"
            @load="setImageWidth(index)"
          >
        </div>
      </div>
      <div
        v-if="hasResponseType"
        :class="!isStudy? 'tw-w-full': ''"
      >
        <div
          v-if="
            (isResponseTypeText && hasSingleMedia) ||
              (isResponseTypeText && hasExternalMedia) ||
              (isResponseTypeText && hasMultipleMedia)
          "
          :class="{
            'st-opinion-metric__text-area-container':
              !isDesktopSurveyPreview && !isDesktopSurvey,
            'st-opinion-metric__text-area-container--focused': textAreaFocused,
            'st-opinion-metric__text-area-container--focused-desktop':
              (textAreaFocused && isDesktopSurveyPreview) ||
              (textAreaFocused && isDesktopSurvey),
            'st-opinion-metric__text-area-container--next-button-visible':
              !isPreview && nextButtonTextArea,
            'st-opinion-metric__text-area-container--next-button': nextButtonTextArea,
            'st-opinion-metric__text-area-container--multi-media': hasMultipleMedia,
            'st-opinion-metric__text-area-container--multi-media-focused':
              hasMultipleMedia && textAreaFocused,
            'st-opinion-metric__text-area-container--specifications-preview': isSpecsDesktopPreview,
            'st-opinion-metric__text-area-container--specifications-preview-next-question':
              isSpecsDesktopPreview && nextButtonTextArea,
            'st-opinion-metric__text-area-container--desktop':
              isDesktopSurveyPreview || isDesktopSurvey,
            'st-opinion-metric__text-area-container--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__text-area-container--multi-media-desktop-survey':
              hasMultipleMedia && isDesktop && nextButtonTextArea,
            'st-opinion-metric__text-area-container--multi-media-specs':
              hasMultipleMedia && isSpecsDesktopPreview,
          }"
        >
          <st-text-area
            v-model="writtenResponse"
            :class="{
              'st-opinion-metric__text-area': true,
              'st-opinion-metric__text-area--next-button-visible-specs':
                isPreview && nextButtonTextArea && !isSurveyPreview,
              'st-opinion-metric__text-area--next-button-visible': nextButtonTextArea,
              'st-opinion-metric__text-area--multi-media': hasMultipleMedia,
              'st-opinion-metric__text-area--multi-media-specs':
                hasMultipleMedia && isSpecsDesktopPreview,
              'st-opinion-metric__text-area--multi-media-focused':
                textAreaFocused && hasMultipleMedia,
            }"
            :button="textAreaFocused"
            :label='$t("shareYourThoughts")'
            :label-size-class="
              (nextButtonTextArea && !isPreview) ||
                (nextButtonTextArea && isSurveyPreview)
                ? 'st-opinion-metric__text-area-label'
                : 'st-opinion-metric__text-area-label--specifications'
            "
            placeholder='$t("tellUsWhatYouThink")'
            @focus="focusTextArea"
            @focusout="focusOutTextArea"
          />
          <st-tooltip
            v-if="nextButtonTextArea"
            class="st-opinion-metric__button-tooltip"
            tip="Preview only button"
            :is-active="isSpecificationsPreview"
          >
            <!-- :variant="['secondary']" -->
            <st-button
              :class="{
                'st-opinion-metric__button st-opinion-metric__next-question-button': true,
                'st-opinion-metric__next-question-button--multi-media':
                  hasMultipleMedia && !isSpecsDesktopPreview,
              }"
              :caption="buttonCaption"
              :disabled="!writtenResponse.length"
              icon-name="arrow"
              :underline="false"
              @click="beforeSubmit"
            />
            <p
              v-if="optional"
              class="tw-py-2 tw-text-center tw-text-primary tw-text-sm hover:tw-underline tw-cursor-pointer"
              @click="skipThisQuestion"
            >
            {{ $t("skipThisQuestion") }}
            </p>
            <!-- @click="submitQuestionAnswer" -->
          </st-tooltip>
        </div>
        <div
          v-else-if="
            
              !isResponseTypeText || customResponseOption === 'voice_recording'
          "
          :class="{
            'tw-shadow-none tw-w-full !tw-relative !tw-bg-transparent !tw-px-0': !isStudy,
            'st-opinion-metric__voice-response-container mb-7-important': true,
            'st-opinion-metric__voice-response-container--desktop':
              !hasMultipleMedia && isDesktop,

            'st-opinion-metric__voice-response-container--multi-media-no-description-survey':
              !description &&
              hasMultipleMedia &&
              isDesktop &&
              !isSpecsDesktopPreview,
            'st-opinion-metric__voice-response-container--multi-media-survey-preview': isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media-specs-preview':
              hasMultipleMedia && isSpecsDesktopPreview,
            'st-opinion-metric__voice-response-container--multi-media-preview-maximize':
              zoomLevel > 100 && isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media-no-description-survey-preview':
              !description && isMultiMediaDesktopSurveyPreview,
            'st-opinion-metric__voice-response-container--multi-media': hasMultipleMedia,
          }"
        >
          <div
            v-if="
              hasMultipleMedia &&
                isDesktop &&
                !isSpecsDesktopPreview &&
                description
            "
            ref="questionContainer"
            :class="{
              'st-opinion-metric__full-width-description': true,
              'st-opinion-metric__full-width-description--desktop-preview': isDesktopSurveyPreview,
              'st-opinion-metric__full-width-description--specs-preview': isSpecsDesktopPreview,
            }"
          >
            <p ref="question">
              {{ description }}
            </p>
          </div>

          <div
            v-if="!isStudy"
            class="tw-w-full"
          >
            <button
              v-if="isRecordButton"
              :style="buttonStyle"
              :class="{
                'st-opinion-metric__button st-opinion-metric__record-thoughts-button tw-rounded-lg tw-w-full': true,
                'st-opinion-metric__record-thoughts--multi-media':
                  hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
                'st-opinion-metric__record-thoughts--multi-media-no-description':
                  hasMultipleMedia && isDesktopSurvey && !description,
                'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
              }"
              :caption='$t("recordYourThoughts")'
              :underline="false"
              @click="countingTimeSpent"
            >
              
              {{ $t("startRecording") }}
            </button>
            <p
              v-if="optional && isRecordButton"
              class="tw-py-2 tw-text-center tw-text-primary tw-text-sm hover:tw-underline tw-cursor-pointer"
              @click="skipThisQuestion"
            >
            {{ $t("skipThisQuestion") }}
            </p>

            <!-- :variant="['secondary']" -->
            <div
              v-else-if="isStopButton"
              :class="{
                'st-opinion-metric__stop-button-and-visualizer': true,
                'st-opinion-metric__record-thoughts--multi-media':
                  hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
                'st-opinion-metric__record-thoughts--multi-media-no-description':
                  hasMultipleMedia && isDesktopSurvey && !description,
                'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
              }"
            >
              <!-- <button
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="stopRecording"
            >
              <div class="st-opinion-metric__stop-square" />
            </button> -->
              <canvas
                v-if="isVisualizerVisible"
                ref="audioCanvas"
                :width="canvasWidth"
                :height="canvasHeight"
                :class="{
                  'st-opinion-metric__audio-visualizer': true,
                  'st-opinion-metric__audio-visualizer--preview': isSpecsDesktopPreview,
                }"
              />

              <span
                class="recording-button-red"
                @click="stopCounting"
              >
                <icon-wrapper
                  class="st-opinion-metric__stop-recording-button"
                  icon-name="stop-recording"
                />
                <span class="cursor-pointer record-timer">
                  <span>{{ mins < 9 ? `0${mins}` : mins }}</span>:<span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
                </span>
              </span>
            </div>
          </div>
          <div v-else>
            <div v-if="isRecordButton">
              <st-button

                :class="{
                  'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
                  'st-opinion-metric__record-thoughts--multi-media':
                    hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
                  'st-opinion-metric__record-thoughts--multi-media-no-description':
                    hasMultipleMedia && isDesktopSurvey && !description,
                  'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
                }"
                :caption='$t("recordYourThoughts")'
                :underline="false"
                @click="countingTimeSpent"
              />
              <p
                v-if="optional && isRecordButton"
                class="tw-py-2 tw-text-center tw-text-primary tw-text-sm hover:tw-underline tw-cursor-pointer"
                @click="skipThisQuestion"
              >
               {{ $t("skipThisQuestion") }}
              </p>
            </div>
            <!-- :variant="['secondary']" -->
            <div
              v-else-if="isStopButton"
              :class="{
                'st-opinion-metric__stop-button-and-visualizer': true,
                'st-opinion-metric__record-thoughts--multi-media':
                  hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
                'st-opinion-metric__record-thoughts--multi-media-no-description':
                  hasMultipleMedia && isDesktopSurvey && !description,
                'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
              }"
            >
              <!-- <button
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="stopRecording"
            >
              <div class="st-opinion-metric__stop-square" />
            </button> -->
              <canvas
                v-if="isVisualizerVisible"
                ref="audioCanvas"
                :width="canvasWidth"
                :height="canvasHeight"
                :class="{
                  'st-opinion-metric__audio-visualizer': true,
                  'st-opinion-metric__audio-visualizer--preview': isSpecsDesktopPreview,
                }"
              />

              <span
                class="recording-button-red"
                @click="stopCounting"
              >
                <icon-wrapper
                  class="st-opinion-metric__stop-recording-button"
                  icon-name="stop-recording"
                />
                <span class="cursor-pointer record-timer">
                  <span>{{ mins < 9 ? `0${mins}` : mins }}</span>:<span>{{ seconds < 10 ? `0${seconds}` : seconds }}</span>
                </span>
              </span>
            </div>
          </div>
          <audio
            v-if="isAudioPlayer"
            ref="audioPlayer"
            class="st-opinion-metric__audio-player"
            controls
          >
            Your browser does not support the <code>audio</code> element.
          </audio>
          <st-tooltip
            v-if="errorMessage"
            :always-visible="true"
            :max-width="errorTooltipWidth"
            :tip="errorMessage"
            message-class="st-opinion-metric__button-tooltip--error"
            :class="{
              'st-opinion-metric__button st-opinion-metric__record-thoughts-button': true,
              'st-opinion-metric__record-thoughts--multi-media':
                hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description':
                hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
            }"
            :is-active="isSpecificationsPreview"
          >
            <st-button
              :caption="buttonCaption"
              class="st-opinion-metric__button"
              icon-name="arrow"
              :underline="false"
              @click="beforeSubmit"
            />
            <p
              v-if="optional"
              class="tw-py-2 tw-text-center tw-text-primary tw-text-sm hover:tw-underline tw-cursor-pointer"
              @click="skipThisQuestion"
            >
            {{ $t("skipThisQuestion") }}
            </p>
            <!-- :variant="['secondary']" -->
            <!-- @click="submitQuestionAnswer" -->
          </st-tooltip>
          <div
            v-if="readyToSubmit && !isStudy"
            :class="{
              'st-opinion-metric__ready-to-submit': true,
              'st-opinion-metric__record-thoughts--multi-media':
                hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description':
                hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
            }"
          >
            <!-- <button
              v-if="readyToSubmit && !replayAudio"
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="playAgain"
            >
              <icon-wrapper
                class="st-opinion-metric__play-button"
                icon-name="play-button"
                :spacetrics-blue="true"
              />
            </button> -->
            <div class="st-opinion-metric__recording-container">
              <div class="st-opinion-metric__recording-col">
                <span
                  v-if="readyToSubmit && !replayAudio"
                  class="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-h-7 tw-w-7"
                  :style="playButtonStyle"
                  @click="playAgain"
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="tw-h-5 tw-w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                      clip-rule="evenodd"
                    />
                  </svg>

                </span>
                <!-- <button
                  v-if="readyToSubmit && replayAudio"
                  type="button"
                  class="st-opinion-metric__audio-button-container"
                  @click="stopReplay"
                >
                  <div class="st-opinion-metric__pause-bars">
                    &#9612;
                  </div>
                  <div class="st-opinion-metric__pause-bars--right st-opinion-metric__pause-bars">
                    &#9612;
                  </div>
                </button> -->
                <span
                  v-if="readyToSubmit && replayAudio"
                  class="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-h-7 tw-w-7"
                  :style="playButtonStyle"
                  @click="stopReplay"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                    />
                  </svg>

                </span>

                <span class="!tw-w-[70%] line" />
                <p
                  v-if="readyToSubmit"
                  class="tw-text-xs st-opinion-metric__audio-duration"
                >
                  {{ formatAudioDuration }}
                </p>
              </div>
            </div>
            <div
              v-if="readyToSubmit"
              class="bottom-button-container tw-space-x-2"
            >
              <button
                class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-px-4 tw-py-3 tw-rounded-md tw-h-full tw-w-full"
                :style="secondaryButtonStyle"
                @click="rerecord"
              >
                <span
                  class="tw-flex tw-items-center"
                >
                {{ $t("rerecord") }}
              </span>
              </button>
              <button
                class="tw-flex tw-justify-center tw-items-center tw-space-x-3 tw-px-4 tw-py-3 tw-rounded-md tw-w-full"
                :style="buttonStyle"
                @click="beforeSubmit"
              >
                <span
                  class="tw-flex tw-items-center"
                >{{ $t("rerecord") }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="tw-h-4 tw-w-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
              <!-- <div
                  v-if="readyToSubmit"
                  class="st-opinion-metric__trash-icon-container"
                  @click="rerecord"
                >
                  <icon-wrapper
                    class="st-opinion-metric__trash-icon"
                    icon-name="restart-recording"
                  />
                </div> -->
              <!-- <div class="st-opinion-metric__recording-col">
                <st-tooltip

                  class="st-opinion-metric__button-tooltip"
                  tip="Preview only button"
                  :is-active="isSpecificationsPreview"
                >
                  <st-button
                    class="st-opinion-metric__button"
                    :caption="buttonCaption"
                    :underline="false"
                    :variant="['secondary']"
                    @click="submitQuestionAnswer"
                  />
                </st-tooltip>
              </div> -->

              <!-- class="submit-button-special" -->
            </div>
          </div>
          <div
            v-else
            :class="{
              'st-opinion-metric__ready-to-submit': true,
              'st-opinion-metric__record-thoughts--multi-media':
                hasMultipleMedia && !isSpecsDesktopPreview && isDesktop,
              'st-opinion-metric__record-thoughts--multi-media-no-description':
                hasMultipleMedia && isDesktopSurvey && !description,
              'st-opinion-metric__record-thoughts--multi-media-desktop-preview': isMultiMediaDesktopSurveyPreview,
            }"
          >
            <!-- <button
              v-if="readyToSubmit && !replayAudio"
              type="button"
              class="st-opinion-metric__audio-button-container"
              @click="playAgain"
            >
              <icon-wrapper
                class="st-opinion-metric__play-button"
                icon-name="play-button"
                :spacetrics-blue="true"
              />
            </button> -->
            <div class="st-opinion-metric__recording-container">
              <div class="st-opinion-metric__recording-col">
                <span
                  v-if="readyToSubmit && !replayAudio"
                  @click="playAgain"
                >
                  <icon-wrapper
                    icon-name="play-recording"
                    class="st-opinion-metric__play-recording-button"
                  />
                </span>
                <!-- <button
                  v-if="readyToSubmit && replayAudio"
                  type="button"
                  class="st-opinion-metric__audio-button-container"
                  @click="stopReplay"
                >
                  <div class="st-opinion-metric__pause-bars">
                    &#9612;
                  </div>
                  <div class="st-opinion-metric__pause-bars--right st-opinion-metric__pause-bars">
                    &#9612;
                  </div>
                </button> -->
                <span
                  v-if="readyToSubmit && replayAudio"
                  @click="stopReplay"
                >
                  <icon-wrapper
                    icon-name="pause-recording"
                    class="st-opinion-metric__play-recording-button"
                  />
                </span>
                <p
                  v-if="readyToSubmit"
                  class="st-opinion-metric__audio-duration"
                >
                  {{ formatAudioDuration }}
                </p>

                <!-- <span
                  v-if="isStudy"
                  class="line"
                /> -->
              </div>
            </div>
            <div
              v-if="readyToSubmit"
              class="bottom-button-container"
            >
              <div
                class="rerecord-delete-button"
                @click="rerecord"
              >
                <span style="width:20px; height:20px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </span>
                <span>
                  {{ $t("rerecord") }}
                </span>
              </div>
              <!-- <div
                  v-if="readyToSubmit"
                  class="st-opinion-metric__trash-icon-container"
                  @click="rerecord"
                >
                  <icon-wrapper
                    class="st-opinion-metric__trash-icon"
                    icon-name="restart-recording"
                  />
                </div> -->
              <!-- <div class="st-opinion-metric__recording-col">
                <st-tooltip

                  class="st-opinion-metric__button-tooltip"
                  tip="Preview only button"
                  :is-active="isSpecificationsPreview"
                >
                  <st-button
                    class="st-opinion-metric__button"
                    :caption="buttonCaption"
                    :underline="false"
                    :variant="['secondary']"
                    @click="submitQuestionAnswer"
                  />
                </st-tooltip>
              </div> -->

              <!-- class="submit-button-special" -->
              <st-button
                class="px-10-important py-2 font-16p"
                @click="beforeSubmit"
              >
                <!-- @click="submitQuestionAnswer" -->
                <span class="px-10">
                  {{ $t("submit") }}
                </span>
              </st-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showExpanedPhoto">
      <div
        ref="stModal"
        class="st-modal__opacitor"
        tabindex="0"
        @click="showExpanedPhoto = false"
        @keyup.esc="showExpanedPhoto = false"
      />
      <button
        ref="closeLightboxIcon"
        class="st-opinion-metric-light-box__close-icon-button"
        @click="showExpanedPhoto = false"
      >
        <icon-wrapper
          class="st-opinion-metric-light-box__close-icon"
          :invert="true"
          icon-name="plus"
        />
      </button>
      <img
        ref="lightboxImage"
        :src="lightboxImageSrc"
        class="st-opinion-metric-light-box__media"
        @load="positionCloseIcon"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import PersonImage from '../../../../../assets/images/microphone_person.png'
import {
  audioRecorder,
  handlePreviewBack,
  isDesktop,
  previousResponse,
  submitQuestionAnswer
} from '../../../../mixins'
import { getBlobDuration } from '../../../../utilities/audio_utilities'
import { formatSecondsToStopwatch } from '../../../../utilities/data_utilities'
import {
  IconWrapper,
  StButton,
  StExternalVideo,
  StRadioButton,
  StTextArea,
  StTooltip
} from '../../../common'
import Instructions from '../instructions'
import MicrophoneAccess from '../microphone-access'
function nearestInArray (value, array) {
  return array.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0]
}

export default {
  components: {
    IconWrapper,
    MicrophoneAccess,
    StButton,
    StExternalVideo,
    StTextArea,
    StTooltip,
    Instructions,
    StRadioButton
  },
  mixins: [
    audioRecorder,
    handlePreviewBack,
    isDesktop,
    previousResponse,
    submitQuestionAnswer
  ],
  props: {
    account: {
      type: Object,
      required: false,
      default: () => ({})
    },
    optionForSurvey: {
      type: String,
      required: false
    },
    askForExplanation: {
      type: String,
      required: false,
      default: ''
    },
    autoStopTime: {
      type: Number,
      default: 900000, // ms = 15 min
      required: false
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    isSurveyPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    spacetricsLogo: {
      type: String,
      required: false,
      default: ''
    },
    isJoinSurvey: {
      type: Boolean,
      required: false,
      default: false
    },
    media: {
      type: Object,
      required: false,
      default: () => ({})
    },
    nextPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    previousPreviewQuestion: {
      type: Object,
      required: false,
      default: () => ({})
    },
    priority: {
      type: Number,
      required: true
    },
    questionId: {
      type: Number,
      required: true
    },
    isStudy: {
      type: Boolean,
      required: false,
      default: true
    },
    widgetConfig: {
      type: Object,
      required: false,
      default: () => ({})
    },
    showQuestionInstructions: {
      type: Boolean,
      required: false,
      default: false
    },
    surveyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonCaption: {
      type: String,
      required: false,
      default: ''
    },
    nextQuestionId: {
      type: Number,
      required: false,
      default: null
    },
    activeWidget: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function () {
    return {
      seconds: 0,
      customResponseOption: 'voice_recording',
      followUpAnswer: null,
      showFollowUpQuestion: false,
      mins: 0,
      followUpQuestionText: null,
      intervalId: 0,
      audioBlob: null,
      audioDuration: null,
      errorMessage: '',
      imageWidth: 0,
      lightboxImageSrc: '',
      mobileWindowScrollDistance: 0,
      multiMediaImagesScrollDistance: 0,
      isStickyQuestionActive: false,
      overflowDescription: false,
      readyToSubmit: false, // Change this later
      replayAudio: false,
      resizeObserver: null,
      showExpanedPhoto: false,
      surveyWrapperScrollDistance: 0,
      textAreaFocused: false,
      writtenResponse: '',
      zoomLevel: 100,
      showInstructions: !!(
        this.showQuestionInstructions ||
        this.askForExplanation === 'let_user_decide'
      ),
      audioPermissionGranted: false,
      showToast: false,
      showFollowUp: false,
      showWidgetQuestionOptions: true,
      PersonImagePath: PersonImage,
      requiredTextError: false,
      // permission: false
    }
  },
  computed: {
    ...mapState('participants', {
      participantActiveQuestion: 'activeQuestion',
      opinionMetricPhase: 'opinionMetricPhase'
    }),
    ...mapGetters('specifications', { specsActiveQuestion: 'activeQuestion' }),
    ...mapGetters('participants', ['PHASES']),

    activeQuestion () {
      return this.isSpecsDesktopPreview
        ? this.specsActiveQuestion
        : this.participantActiveQuestion
    },
    containerStyle () {
      if (this.widgetConfig) {
        return { background: this.widgetConfig.background_color }
      } else {
        return {}
      }
    },
    textStyle () {
      if (this.widgetConfig) {
        return { color: this.widgetConfig.text_color }
      } else {
        return {}
      }
    },
    headerStyle () {
      if (this.widgetConfig) {
        return { color: this.widgetConfig.text_color }
      } else {
        return {}
      }
    },
    buttonStyle () {
      if (this.widgetConfig) {
        return { background: this.widgetConfig.primary_button_background_color, color: this.widgetConfig.primary_button_text_color }
      } else {
        return {}
      }
    },
    secondaryButtonStyle () {
      if (this.widgetConfig) {
        return { background: this.widgetConfig.secondary_button_background_color, color: this.widgetConfig.secondary_button_text_color }
      } else {
        return {}
      }
    },
    playButtonStyle () {
      if (this.widgetConfig) {
        return { background: this.widgetConfig.play_button_background_color, color: this.widgetConfig.play_button_text_color }
      } else {
        return {}
      }
    },
    contentType () {
      if (!this.hasSingleMedia) {
        return ''
      }
      if (!this.media.hasOwnProperty('contentType')) {
        return ''
      }
      return this.media.contentType
    },
    errorTooltipWidth () {
      return this.isDesktop ? 411 : window.innerWidth - 40
    },
    formatAudioDuration () {
      return formatSecondsToStopwatch(this.audioDuration)
    },
    hasExternalMedia () {
      return this.activeQuestion.externalVideoUrl
    },
    hasMultipleMedia () {
      return this.options.length > 1
    },
    hasNoMedia () {
      return (
        !this.hasSingleMedia && !this.hasMultipleMedia && !this.hasExternalMedia
      )
    },
    hasSingleMedia () {
      return this.options.length === 1
    },
    hasResponseType () {
      return this.askForExplanation
    },
    isAudioPlayer () {
      return this.audioBlob !== null
    },
    isExpandPhotoIcon () {
      return this.isDesktop
        ? !this.isSpecificationsPreview
        : !this.isSpecificationsPreview && !this.textAreaFocused
    },
    isDesktopSurvey () {
      return this.isDesktop && !this.isPreview
    },
    isDesktopSurveyPreview () {
      return this.isDesktop && this.isSurveyPreview
    },
    isMediaVideo () {
      if (!this.options[0]) {
        return false
      } else {
        return this.options[0].media.contentType.split('/')[0] === 'video'
      }
    },
    isMultiMediaDesktopSurveyPreview () {
      return this.hasMultipleMedia && this.isDesktopSurveyPreview
    },
    isResponseTypeText () {
      return this.askForExplanation === 'text'
    },
    isResponseCustom () {
      return this.askForExplanation === 'let_user_decide'
    },
    isRecordButton () {
      if (
        this.mediaRecorder === null || this.isResponseTypeText ||
        (this.isResponseCustom && this.customResponseOption === 'text')
      ) {
        return false
      }
      if (this.isRecording) {
        return false
      }
      if (this.audioBlob !== null) {
        return false
      }
      return true
    },
    isSpecificationsPreview () {
      return this.isPreview && !this.isSurveyPreview
    },
    isSpecsDesktopPreview () {
      return this.isSpecificationsPreview && this.isDesktop
    },
    isStopButton () {
      return this.isRecording
    },
    isSubmissionSkippable () {
      return (
        this.questionResponses &&
        this.audioBlob === this.questionResponses[0].srcUrl
      )
    },
    mediaExtension () {
      return !this.hasSingleMedia
        ? ''
        : this.options[0].mediaUrl.split('.').pop()
    },
    mediaFormatCategory () {
      return !this.hasSingleMedia
        ? ''
        : this.options[0].media.contentType.split('/')[0]
    },
    mediaFullUrl () {
      return this.options ? this.options[0].mediaFullUrl : ''
    },
    mediaUrl () {
      console.log('mediaUrl', this.options)
      return this.options ? this.options[0].mediaUrl : ''
    },
    nextButtonTextArea () {
      return this.writtenResponse.length && !this.textAreaFocused
    },
    imageStyle () {
      if (this.zoomLevel <= 100) {
        return {
          marginLeft: `${100 - this.zoomLevel}%`,
          marginRight: `${100 - this.zoomLevel}%`
        }
      } else if (this.zoomLevel > 100) {
        return {
          width: `${this.imageWidth * (this.zoomLevel / 100)}px`
        }
      } else {
        return {}
      }
    },
    showAudioInstructions () {
      // display instructions only when question has voice recording requirement
      if (
        (this.askForExplanation === 'voice_recording' ||
          this.askForExplanation === 'let_user_decide') &&
        this.showInstructions
      ) {
        // if (this.permission) return false
        // for specs preview page
        if (!this.isPreview && !this.isSurveyPreview) return true
        // for participant page
        else if (this.isPreview && this.isSurveyPreview) return true
        else return false
      } else {
        return false
      }
    }
  },
  watch: {
    askForExplanation (newValue, oldValue) {
      // this is for specs - we need to update on the fly
      if (newValue === 'voice_recording') {
        this.tryInitializingMediaRecorder()
          .then(() => {})
          .catch(() => {})
      }
    },
    description () {
      this.fitQuestionText()
    },
    options () {
      this.fitQuestionText()
    },
    showInstructions (newValue) {
      if (!newValue) {
        this.$emit('hideLogoHeader', false)
        this.$emit('hideSpacetricsFooter', false)
      }
    },
    customResponseOption (newValue) {
      if (newValue === 'voice_recording') {
        this.tryInitializingMediaRecorder()
          .then(() => {})
          .catch(() => {})
      }
    }
  },
  beforeMount () {
    this.changeLanguage(this.survey.language)
  },
  mounted () {

    // console.log('opinion metric', this.activeWidget, this.nextQuestionId)
    if (this.optionForSurvey === 'text') {
      this.handleDisableInstructions()
      this.showWidgetQuestionOptions = false
      this.customResponseOption = 'text'
    }
    if (this.showAudioInstructions) {
      this.$emit('hideLogoHeader', true)
    }
    this.$emit('hideSpacetricsFooter', true)
    this.$store.commit('hideSidebar', true)

    if (
      (this.hasSingleMedia ||
        (this.hasMultipleMedia && !this.isDesktopSurveyPreview) ||
        this.options.length === 0 ||
        this.hasExternalMedia) &&
      !this.showAudioInstructions
    ) {
      this.resizeObserver = new ResizeObserver(this.fitQuestionText)
      this.resizeObserver.observe(this.$refs.questionContainer)
    }

    if (this.hasSingleMedia && !this.isMediaVideo && !this.hasExternalMedia) {
      this.lightboxImageSrc = this.mediaUrl
      let img = new Image()
      img.onload = () => {
        this.lightboxImageSrc = this.mediaFullUrl
      }
      img.src = this.mediaFullUrl
    }
    if (
      this.hasMultipleMedia &&
      (this.isDesktopSurveyPreview || this.isDesktopSurvey)
    ) {
      this.$parent.$el.parentElement.scrollTop = 0
    }
    if (this.hasMultipleMedia && this.isDesktop) {
      this.$parent.$el.parentElement.addEventListener(
        'scroll',
        this.handleWrapperScroll
      )
    }
    if (this.hasMultipleMedia && !this.isDesktop) {
      window.addEventListener('scroll', this.handleMobileScroll)
    }

    if (
      this.askForExplanation === 'voice_recording' &&
      !this.showInstructions
    ) {
      this.tryInitializingMediaRecorder()
        .then(() => {})
        .catch(() => {})
    }
    if (
      this.optionForSurvey === 'voice'
    ) {
      this.tryInitializingMediaRecorder()
        .then(() => {})
        .catch(() => {})
    }

    // if (!this.permission) {
    //   this.toggleFullSpinner(true)
    //   navigator.permissions
    //     .query({ name: 'microphone' })
    //     .then((permissionStatus) => {
    //       console.log(permissionStatus.state)
    //       if (permissionStatus.state === 'granted') {
    //         // Microphone access is allowed
    //         setTimeout(() => {
    //           this.permission = true
    //           this.tryInitializingMediaRecorder()
    //           this.toggleFullSpinner(false)
    //         }, 1000)
    //       } else if (permissionStatus.state === 'prompt') {
    //         // Microphone access is not determined yet, show a prompt to request permission
    //         setTimeout(() => {
    //           this.permission = false
    //           this.toggleFullSpinner(false)
    //         }, 1000)
    //       } else if (permissionStatus.state === 'denied') {
    //         // Microphone access is denied
    //         setTimeout(() => {
    //           this.permission = false
    //           this.toggleFullSpinner(false)
    //         }, 1000)
    //       }
    //     })
    //     .catch(function (error) {
    //       // Handle error while checking permission
    //       setTimeout(() => {
    //         this.permission = false
    //         this.toggleFullSpinner(false)
    //       }, 1000)
    //       console.log('error: ', error)
    //     })
    // }
  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.questionContainer)
    }
    if (this.hasMultipleMedia && this.isDesktop) {
      window.removeEventListener('scroll', this.handleWrapperScroll)
    }
    if (this.hasMultipleMedia && !this.isDesktop) {
      window.removeEventListener('scroll', this.handleMobileScroll)
    }
  },
  methods: {
    ...mapMutations(['toggleFullSpinner']),
    changeLanguage(lang) {
          this.$i18n.locale = lang;
        },
    addStylesToContent (content) {
    // Add styles to ul and ol elements
      const styledContent = content.replace(/<ul/g, '<ul style="list-style-type: disc !important; padding-left: 1.5rem !important;"')
        .replace(/<ol/g, '<ol style="list-style-type: decimal !important; font-size:18px !important; padding-left: 1.5rem !important; text-align:left !important"').replace(/<a/g, '<a style="color: #BE6744 !important; text-decoration: underline !important;"')

      return styledContent
    },
    skipThisQuestion () {
      if (this.isPreview) {
        if (this.nextQuestionId === 0) {
          window.location.href = `/study/${this.surveyId}/preview_complete`
          return
        }
        this.$store.commit('toggleFullSpinner', true)
        window.location.href = `/questions/${this.nextQuestionId}/preview?participant_token=${this.participantSubmissionToken}`
        this.$store.commit('toggleFullSpinner', false)
        return
      }
      let url = `/questions/${this.questionId}/submit?participant_token=${this.participantSubmissionToken}`
      this.$store.commit('toggleFullSpinner', true)
      let data = new FormData()
      data.append('response[skipped]', true)
      // this.$emit("loading", true);
      this.$axios
        .request({
          url: url,
          method: 'post',
          data: data
        })
        .then((res) => {
          if (res.data.surveyComplete) {
            if (this.noRedirect) {
              this.$store.commit('toggleFullSpinner', false)
              this.handleSubmission()
            } else {
              window.location.href = `/study/${this.surveyId}/complete?participant_token=${this.participantSubmissionToken}`
            }
          } else {
            this.$emit('nextQuestion', res.data.nextQuestion)
            this.setInstructionsPage(1)
            if (
              this.activeQuestion.questionType === 'virtual_tour' &&
              res.data.nextQuestion.questionType === 'virtual_tour'
            ) {
              this.$nextTick(() => {
                this.panoChanges = []
                this.initializeNewMediaRecorder()
                this.setActivePhase('instructions')
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('toggleFullSpinner', false)
          this.$emit('errorMessage')
        })
    },
    typeYourAnswer () {
      this.$emit('changeSurveyOption', 'text')
      this.customResponseOption = 'text'
      this.handleDisableInstructions()
    },
    hideWidgetScreen () {
      this.showWidgetQuestionOptions = false
    },
    askForAudioPermission (incrementPage) {
      if (
        !this.audioPermissionGranted &&
        (this.askForExplanation === 'voice_recording' ||
          this.askForExplanation === 'let_user_decide')
      ) {
        if (this.askForExplanation === 'let_user_decide') {
          this.$emit('changeSurveyOption', 'voice')
        }
        this.errorMessage = null
        this.showToast = true
        this.toggleFullSpinner(true)
        this.$nextTick(() => {
          this.tryInitializingMediaRecorder(incrementPage)
            .then(() => {
              console.log('good')
              this.toggleFullSpinner(false)
              this.handleDisableInstructions()
              this.showWidgetQuestionOptions = false
            })
            .catch(() => {
              console.log('bad')
              this.toggleFullSpinner(false)
            })
        })
      }
    },
    changeToTextResponse () {
      this.$emit('changeSurveyOption', 'text')
      this.handleDisableInstructions()
      this.customResponseOption = 'text'
    },
    countingTimeSpent () {
      this.initiateRecording()
      this.intervalId = setInterval(() => {
        this.seconds++
        if (this.seconds > 59) {
          this.seconds = 0
          this.mins++
        }
      }, 1000)
    },

    stopCounting () {
      if (
        this.participantActiveQuestion.followUpQuestion != null &&
        !this.showFollowUpQuestion
      ) {
        this.toggleFullSpinner(true)
        setTimeout(() => {
          this.toggleFullSpinner(false)
          this.showFollowUpQuestion = true
          this.followUpQuestionText = this.participantActiveQuestion.followUpQuestion
          // call the function defined in parent component 
          this.$emit('hideQuestionTextHandler')
        }, 1000)
      } else {
        clearInterval(this.intervalId)
        this.stopRecording()
        this.seconds = 0
        this.minutes = 0
      }
    },
    answerData () {
      let answer = new FormData()
      answer.append(
        ...(this.isResponseTypeText ||
        (this.isResponseCustom && this.customResponseOption === 'text')
          ? ['response[transcript]', this.writtenResponse]
          : ['response[audio_response]', this.audioBlob])
      )
      return answer
    },
    expandPhoto () {
      if (!this.isSpecsDesktopPreview) {
        this.showExpanedPhoto = true
      }
    },
    setImageWidth (index) {
      if (index === 0) {
        this.imageWidth = this.$refs.multiMedia[0].clientWidth
      }
    },
    handleDisableInstructions () {
      this.showInstructions = false
      this.$nextTick(() => {
        this.resizeObserver = new ResizeObserver(this.fitQuestionText)
        this.resizeObserver.observe(this.$refs.questionContainer)
      })
    },
    handleFixedImagesScroll () {
      this.multiMediaImagesScrollDistance = this.$refs.multiMediaImages.scrollTop
    },
    handleWrapperScroll () {
      this.surveyWrapperScrollDistance = this.$parent.$el.parentElement.scrollTop
    },
    handleMobileScroll () {
      this.mobileWindowScrollDistance = window.top.scrollY
      window.top.scrollY >= this.$refs.question.getBoundingClientRect().top
        ? (this.isStickyQuestionActive = true)
        : (this.isStickyQuestionActive = false)
    },
    isMultiMediaVideo (media) {
      return media.media.contentType.split('/')[0] === 'video'
    },
    positionCloseIcon () {
      let lightBoxImagePosition = this.$refs.lightboxImage.getBoundingClientRect()

      if (this.isDesktop) {
        this.$refs.closeLightboxIcon.style.left =
          lightBoxImagePosition.right + 10 + 'px'
        this.$refs.closeLightboxIcon.style.top =
          lightBoxImagePosition.top + 'px'
      } else {
        this.$refs.closeLightboxIcon.style.left =
          lightBoxImagePosition.right - 8 + 'px'
        this.$refs.closeLightboxIcon.style.top =
          lightBoxImagePosition.top - 24 + 'px'
      }
    },
    fitQuestionText () {
      this.$nextTick(() => {
        let textDiv = this.$refs.questionContainer
        let textSpan = this.$refs.question
        let fontSize = this.hasMultipleMedia && this.isDesktop ? 18 : 26
        if (textSpan) {
          textSpan.style.fontSize = `${fontSize}px`
          while (textSpan.offsetHeight > textDiv.offsetHeight) {
            if (fontSize <= 12) {
              textDiv.style.overflowY = 'scroll'
              this.overflowDescription = true
              break
            }
            fontSize--
            textSpan.style.fontSize = `${fontSize}px`
          }
        }
      })
    },
    focusOutTextArea () {
      this.textAreaFocused = false

      if (this.hasSingleMedia) {
        this.$emit('hideLogoHeader', false)
        this.$emit('hideSpacetricsFooter', false)
      }
    },
    focusTextArea () {
      this.textAreaFocused = true
      if (this.hasSingleMedia) {
        this.$emit('hideLogoHeader', true)
        this.$emit('hideSpacetricsFooter', true)
      }
    },
    loadResponses () {
      if (
        !this.isResponseTypeText &&
        this.questionResponses &&
        this.questionResponses.length > 0 &&
        !this.showAudioInstructions
      ) {
        this.audioBlob = this.questionResponses[0].srcUrl
        this.$nextTick(() => {
          this.$refs.audioPlayer.src = this.questionResponses[0].srcUrl
          this.$refs.audioPlayer.load()
          this.readyToSubmit = true
        })
      }
    },
    onRecordingAvailable (blob) {
      this.audioBlob = blob
      this.readyToSubmit = true
      getBlobDuration(this.audioBlob).then((duration) => {
        this.audioDuration = duration
      })

      this.$nextTick(() => {
        this.setAudioSrc()
      })
    },
    playAgain () {
      this.$refs.audioPlayer.play()
      this.$refs.audioPlayer.ontimeupdate = () => {
        this.audioDuration = this.$refs.audioPlayer.currentTime
        if (
          this.$refs.audioPlayer.duration === this.$refs.audioPlayer.currentTime
        ) {
          this.replayAudio = false
        }
      }
      this.replayAudio = true
    },
    rerecord () {
      this.audioBlob = null
      this.isRecording = false
      this.readyToSubmit = false
      if (this.participantActiveQuestion.followUpQuestion) {
        this.showFollowUpQuestion = false
        this.followUpQuestionText = this.participantActiveQuestion.question
      }
    },
    resetZoomLevel () {
      if (this.isDesktopSurveyPreview) {
        this.zoomLevel = 100
      }
    },
    setAudioSrc (audioUrl = '') {
      if (!this.isSpecificationsPreview) {
        audioUrl = window.URL.createObjectURL(this.audioBlob)
      }
      this.$refs.audioPlayer.src = audioUrl
      this.$refs.audioPlayer.load()
    },
    stopReplay () {
      this.replayAudio = false
      this.$refs.audioPlayer.pause()
    },
    handleZoomIn () {
      this.zoomLevel += 10
      this.$nextTick(() => {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? this.zoomIn(
            this.multiMediaImagesScrollDistance,
            this.surveyWrapperScrollDistance
          )
          : this.zoomIn(
            this.multiMediaImagesScrollDistance,
            this.mobileWindowScrollDistance
          )
      })
    },
    zoomIn (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map((media) => {
        return media.offsetTop
      })
      if (this.zoomLevel === 110) {
        this.$refs.multiMediaImages.addEventListener(
          'scroll',
          this.handleFixedImagesScroll
        )
        this.$refs.multiMediaImages.scrollTop = nearestInArray(
          parentScrollDistance,
          imageOffsets
        )
      } else if (this.zoomLevel > 110) {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(
          fixedScrollDistance,
          imageOffsets
        )
      } else {
        this.$parent.$el.parentElement.scrollTop = nearestInArray(
          parentScrollDistance,
          imageOffsets
        )
      }
    },
    handleZoomOut () {
      if (this.zoomLevel > 60) {
        this.zoomLevel -= 10
        this.$nextTick(() => {
          this.isDesktopSurvey || this.isDesktopSurveyPreview
            ? this.zoomOut(
              this.multiMediaImagesScrollDistance,
              this.surveyWrapperScrollDistance
            )
            : this.zoomOut(
              this.multiMediaImagesScrollDistance,
              this.mobileWindowScrollDistance
            )
        })
      }
    },
    zoomOut (fixedScrollDistance, parentScrollDistance) {
      let imageOffsets = this.$refs.multiMedia.map((media) => {
        return media.offsetTop
      })
      if (this.zoomLevel === 100) {
        this.isDesktopSurvey || this.isDesktopSurveyPreview
          ? (this.$parent.$el.parentElement.scrollTop = nearestInArray(
            fixedScrollDistance,
            imageOffsets
          ))
          : window.scrollTo({
            top: nearestInArray(fixedScrollDistance, imageOffsets),
            behavior: 'instant'
          })
      } else if (this.zoomLevel < 100) {
        this.$parent.$el.parentElement.scrollTop = nearestInArray(
          parentScrollDistance,
          imageOffsets
        )
      } else {
        this.$refs.multiMediaImages.scrollTop = nearestInArray(
          fixedScrollDistance,
          imageOffsets
        )
      }
    },
    async beforeSubmit () {
      // console.log("submitting question answer")
      // console.log("isStudy", this.isStudy)
      // console.log("this.isResponseTypeText", this.isResponseTypeText)
      // console.log("this.customResponseOption === 'text", this.customResponseOption === 'text')
      // console.log("this.participantActiveQuestion.followUpQuestion", this.participantActiveQuestion.followUpQuestion)
      if(!this.showFollowUpQuestion){

        if (!this.isStudy && (this.isResponseTypeText || this.customResponseOption === 'text')) {
          // console.log("first if")
          if (!this.writtenResponse || this.writtenResponse == '') {
            // console.log("error if")
            this.requiredTextError = true
            return
          }
        }
      }
      this.requiredTextError = false
      if (this.participantActiveQuestion.followUpQuestion != null) {
        // console.log("second if")
        if (this.showFollowUpQuestion) {
          this.writtenResponse =
            this.writtenResponse + '. ' + this.followUpAnswer
            setTimeout(()=>{
              this.$emit('showQuestionTextHandler')
            }, 300)
          this.submitQuestionAnswer()
        } else {
          this.toggleFullSpinner(true)
          setTimeout(() => {
            this.toggleFullSpinner(false)
            this.showFollowUpQuestion = true
            this.$emit('hideQuestionTextHandler')
            this.followUpQuestionText = this.participantActiveQuestion.followUpQuestion
          }, 1000)
        }
      } else {
        
        // this.writtenResponse = this.writtenResponse + '. ' + this.followUpAnswer
        // console.log(this.writtenResponse)
        this.submitQuestionAnswer()
      }
      // if (this.participantActiveQuestion.followUpQuestion) {
      //   this.writtenResponse = this.writtenResponse + '. ' + this.followUpAnswer
      //   console.log(this.writtenResponse)
      //   this.submitQuestionAnswer()
      // } else {
      //   const response = await this.$axios.request({
      //     method: 'get',
      //     url: `/study/${this.surveyId}`
      //   })
      //   console.log(response.data.followUpQuestion)
      //   if (response.data.followUpQuestion) {
      //     this.participantActiveQuestion.followUpQuestion = true
      //     this.followUpQuestionText = response.data.followUpQuestion
      //   }
      // }
      // console.log('before submit')
    }
  }
}
</script>
<style scoped>
@media (min-width: 768px) {
  .place-end-desktop {
    display: flex;
    /* padding-top: 450px; */
    justify-content: end;
  }
  .max-height-550p {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
