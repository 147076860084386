<template>
  <div class="">
    <div
      v-for="([key, value], index) in Object.entries(versionDescription)"
      :id="key"
      :key="`${key[0]}_${key[1]}_v_${key[2]}`"
      class="tw-max-h-full tw-group tw-relative"
      :class="{
        'tw-bg-blue-100/60': highlightedKey === key,
        'tw-border-b tw-border-solid tw-border-gray-800 tw-mb-2 py-2 px-5 ': getIndentationLevel() === 0 && value && !isPDFGenerating && index !== Object.entries(versionDescription).length - 1,
      }"
    >
    <!-- {{ getIndentationLevel() }} -->
    
       <div
        v-if="getIndentationLevel()==0 && highlightedKey !==key && !feedbackTimeExpired && showSectionFeedback"
        class=" tw-hidden group-hover:tw-inline-block tw-absolute top-1/2 -tw-right-6 tw-top-12 tw-z-10 tw-space-y-1  tw-bg-white tw-p-1 tw-rounded-full "
        style="box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);"
      >
        <span
          class="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-bg-primary tw-rounded-full tw-cursor-pointer hover:tw-bg-primary-dark"
          @click="highlightSection(key, 'voice')"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1685_466)">
              <path
                d="M2 7.33337H3.5C3.5 8.76232 4.35757 10.0831 5.75 10.7974C7.14243 11.5117 8.85757 11.5117 10.25 10.7974C11.6424 10.0827 12.5 8.76232 12.5 7.33337H14C13.9987 8.63165 13.4647 9.88461 12.4983 10.8579C11.5319 11.8313 10.199 12.4576 8.75 12.6198V14.6667H11V16H5V14.6667H7.25V12.6198C5.801 12.4576 4.46814 11.8313 3.50171 10.8579C2.53529 9.88461 2.00129 8.63165 2 7.33337ZM4.25 7.33337V3.33337C4.25 2.14251 4.96486 1.04194 6.125 0.44651C7.28514 -0.148918 8.71486 -0.148918 9.875 0.44651C11.0351 1.04194 11.75 2.14251 11.75 3.33337V7.33337C11.75 8.52423 11.0351 9.6248 9.875 10.2202C8.71486 10.8157 7.28514 10.8157 6.125 10.2202C4.96486 9.6248 4.25 8.52423 4.25 7.33337ZM5.75 7.33337C5.75 8.04803 6.179 8.70823 6.875 9.06518C7.571 9.42251 8.429 9.42251 9.125 9.06518C9.821 8.70784 10.25 8.04765 10.25 7.33337H8.75V6.00003H10.25V4.00003H8.75V2.6667H10.115C9.87329 2.07394 9.33071 1.61756 8.65229 1.4347C7.97343 1.25184 7.23757 1.36461 6.66629 1.73794C6.095 2.11127 5.75557 2.70289 5.75 3.33337V7.33337Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1685_466">
                <rect
                  width="12"
                  height="16"
                  fill="white"
                  transform="translate(2)"
                />
              </clipPath>
            </defs>
          </svg>

        </span>
        <span
          class="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-bg-white hover:tw-bg-white-two tw-rounded-full tw-cursor-pointer"
          @click="highlightSection(key, 'text')"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66732 12.6666C2.30065 12.6666 1.98687 12.5362 1.72598 12.2753C1.4651 12.0144 1.33443 11.7004 1.33398 11.3333V4.66665C1.33398 4.29998 1.46465 3.9862 1.72598 3.72531C1.98732 3.46442 2.3011 3.33376 2.66732 3.33331H13.334C13.7007 3.33331 14.0147 3.46398 14.276 3.72531C14.5373 3.98665 14.6678 4.30042 14.6673 4.66665V11.3333C14.6673 11.7 14.5369 12.014 14.276 12.2753C14.0151 12.5366 13.7011 12.6671 13.334 12.6666H2.66732ZM2.66732 11.3333H13.334V4.66665H2.66732V11.3333ZM5.33398 10.6666H10.6673V9.33331H5.33398V10.6666ZM3.33398 8.66665H4.66732V7.33331H3.33398V8.66665ZM5.33398 8.66665H6.66732V7.33331H5.33398V8.66665ZM7.33398 8.66665H8.66732V7.33331H7.33398V8.66665ZM9.33398 8.66665H10.6673V7.33331H9.33398V8.66665ZM11.334 8.66665H12.6673V7.33331H11.334V8.66665ZM3.33398 6.66665H4.66732V5.33331H3.33398V6.66665ZM5.33398 6.66665H6.66732V5.33331H5.33398V6.66665ZM7.33398 6.66665H8.66732V5.33331H7.33398V6.66665ZM9.33398 6.66665H10.6673V5.33331H9.33398V6.66665ZM11.334 6.66665H12.6673V5.33331H11.334V6.66665Z"
              fill="#474747"
            />
          </svg>

        </span>
      </div>
      <div
        v-else-if="getIndentationLevel()==0 && highlightedKey ===key"
        class="tw-absolute top-1/2 -tw-right-6 tw-top-12 tw-z-10 tw-space-y-1   tw-p-1 tw-rounded-full"
      >
        <section-feedback
          :highlighted-key="key"
          :feedback-type="feedbackType"
          @cancel="cancelFeedback"
          @feedback-submitted="feedbackSubmitted"
        />
      </div>
      <div
        class=""
        :style="{ marginLeft: getIndentationLevel() + 'px' }"
        :class="{
          
        }"
        >
        <p
          v-if="(typeof key) !== 'number' && !thematicExcludedKeys.includes(key)"
          class="tw-font-semibold tw-pb-1 tw-capitalize"
          :style="{
            fontSize: getFontSize(getIndentationLevel()),
          }
          "
        >
          {{ removeUnderscore(key) }}
        </p>
        <ReportRenderer
          v-if="typeof value === 'object' && !Array.isArray(value) "
          :version-description="value"
          :is-editing-mode="isEditingMode"
          :save-changes="onBlurHandler"
          :show-section-feedback="showSectionFeedback"
          :type="type"
          :isPDFDownloading="isPDFGenerating"
          @save-changes="onBlurHandler"
        />
      </div>
      <template v-if="Array.isArray(value)">
        <div :style="{ marginLeft: getIndentationLevel() + 'px' }">
          <template
            v-for="(item, index) in value"
          >
            <ReportRenderer
              v-if="typeof item === 'object' && !Array.isArray(item)"
                :key="`item_${index}`"
              :version-description="item"
              :is-editing-mode="isEditingMode"
              :save-changes="onBlurHandler"
              :show-section-feedback="showSectionFeedback"
              :isPDFDownloading="isPDFGenerating"
              :type="type"
              @save-changes="onBlurHandler"
            />
            <ul
              v-else
              :key="`item_else_${index}`"
              class="tw-pb-1 tw-pl-10"
            >
              <li
                v-if="!isEditingMode"
                :key="item"
                class="tw-text-[15px] tw-py-1"
              >
                {{ item }}
            </li>
              <textarea
                v-else-if="isEditingMode"
                ref="textareaOne"
                v-model="versionDescription[key][index]"
                class="tw-w-full tw-p-2 tw-border tw-my-2 tw-border-gray-300 tw-rounded-md scrollbar-thin tw-overflow-auto scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black tw-h-auto "
                @input="adjustHeightOfTextArea"
                @blur="onBlurHandler"
              />
            </ul>
          </template>
        </div>
      </template>
      <!-- ONLY FOR THEMATIC ANALYSIS -->
      <template v-if="!isEditingMode && type=='thematicAnalysis'">
       <div class="tw-ml-10">
        <thematic-analysis
          v-if="key === 'data_collection_and_analysis'"
          :data="value"
          section="collection"
        />
        <thematic-analysis
          v-if="key === 'major_themes' || key === 'thematic_divergence' || key === 'thematic_alignment' || key === 'thematic_breakdown'"
          :data="value"
          section="thematicBreakdown"
        />
       </div>
        <!-- {{ key === 'data_collection_and_analysis' && value }} -->
      </template>
      <template v-if="(typeof value != 'object') ">
        <div
          v-if="!isEditingMode && !thematicExcludedKeys.includes(key)"
          :style="{ marginLeft: getIndentationLevel() + 'px' }"
        >
          <p class="tw-text-[15px]">
            {{ value }}
          </p>
        </div>
        <textarea
          v-else-if="isEditingMode"
          ref="textareaTwo"
          v-model="versionDescription[key]"
          :style="{ marginLeft: getIndentationLevel() + 'px' }"
          class="tw-w-full tw-p-2 tw-border tw-my-2 tw-border-gray-300 tw-rounded-md scrollbar-thin tw-overflow-auto scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black tw-h-auto"
          @input="adjustHeightOfTextArea"
          @blur="onBlurHandler"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SectionFeedback from './section-feedback.vue'
import ThematicAnalysis from './ThematicAnalysis.vue'
export default {
  name: 'ReportRenderer',
  components: {
    SectionFeedback,
    ThematicAnalysis
  },
  props: {
    versionDescription: {
      type: [Object, Array, String],
      required: true,
      validator: (value) => value instanceof Object || Array.isArray(value) || typeof value === 'string'
    },
    isPDFGenerating : {
      type: Boolean,
      default: false,
      // required: true
    },
    isEditingMode: {
      type: Boolean,
      default: false
    },
    type : {
      type: String,
      required:false
    },
    feedbackTimeExpired: {
      type: Boolean,
      default: false
    },
    saveChanges: {
      type: Function,
      default: () => {}
    },
    saveReportChanges: {
      type: Function,
      default: () => {}
    },
    showSectionFeedback: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      highlightedKey: null,
      feedbackType: null,
      thematicExcludedKeys: ['number_of_participants', 'total_number_of_answers', 'approximate_total_speaking_time','representative_quotes', 'name', 'quote', 'theme', 'description',]
    
    }
    // data_collection_and_analysis
  },
  // watch for isEditingMode and adjust the height of the textarea
  watch: {
    isEditingMode (val) {
      if (val) {
        this.$nextTick(() => {
          // console.log(this.$refs.textareaOne)
          // console.log(this.$refs.textareaTwo)
          setTimeout(() => {
            const textAreas = document.querySelectorAll('textarea')
            textAreas.forEach((textarea) => {
              textarea.style.height = 'auto'
              textarea.style.height = (textarea.scrollHeight) + 10 + 'px'
            })
          }, 10)
          // this.$refs.textareaTwo.style.height = 'auto'
          // this.$refs.textareaTwo.style.height = (this.$refs.textareaTwo.scrollHeight) + 'px'
        })
      }
    }
  },
  mounted () {
  },
  createRandomKey () {
    return Math.random().toString(36).substring(7)
  },
  methods: {
    feedbackSubmitted (feedback) {
      this.$emit('feedback-submitted', feedback)
    },
    adjustHeightOfTextArea (event) {
      event.target.style.height = 'auto'
      event.target.style.height = (event.target.scrollHeight) + 10 + 'px'
    },
    onBlurHandler () {
      
      if (this.getIndentationLevel() === 0) {
        this.saveReportChanges(this.versionDescription)
      } else {
        this.saveChanges(this.versionDescription)
      }
      // this.$emit('save-changes', this.versionDescription)
    },
    cancelFeedback () {
      this.highlightedKey = null
      this.feedbackType = null
    },
    removeUnderscore (string) {
      return string.replace(/_/g, ' ')
    },
    highlightSection (key, feedbackType) {
      this.highlightedKey = key
      this.feedbackType = feedbackType
    },
    getFontSize (level) {
      if (level === 0) {
        return '22px'
      } else if (level === 10) {
        return '18px'
      } else if (level === 20) {
        return '16px'
      } else if (level === 3) {
        return '15px'
      } else return '15px'
    },
    getIndentationLevel () {
      let level = this.isPDFGenerating ? 1 : 0
      let parent = this.$parent

      while (parent && parent.$options.name === 'ReportRenderer') {
        level++
        parent = parent.$parent
      }

      // Assuming each level of indentation is 20px, adjust as needed
      return level * 10
    }
  }
}
</script>
<style>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #050505;
  color: #ffffff;
  font-size: 14px;
  border-radius: 999px;
  position: absolute;
  padding: 2px 4px 4px 2px;
  bottom: -1.6em;
  right: 10px;
  white-space: nowrap;
  /* box-shadow: 1px 1px 3px #b3b3b3; */
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
</style>
