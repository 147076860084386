<template>
  <div class="tw-h-full  ">
    <st-toast-notification
      v-if="showToast"
      kind="info"
      title="Info"
      :sub-title="toastMessage"
      @click="showToast = false"
    />

    <preview-bar
      :survey-id="survey.id"
      :show-preview="true"
    />
    <div class="tw-flex tw-h-full  tw-justify-center tw-min-h-[85vh]">
      <!-- {{ isStaticStudy }}
      {{ isVideoStudy }}
      {{ currentStep }} -->
      <!-- STEP 0 --- INITIAL STEP -->
      <div
        v-if="currentStep==0"
        class="tw-w-full  "
      >
        <div class="tw-flex tw-w-full tw-justify-between">
          <div class="flex tw-justify-center tw-w-full tw-max-h-full tw-items-center tw-min-h-[80vh]">
            <div
              class="tw-max-w-[520px] tw-min-w-[500px] tw-mx-auto h-full tw-flex tw-flex-col tw-justify-center"
            >
              <!-- <div
                class="tw-p-5 tw-cursor-pointer tw-rounded-xl hover:tw-border-black hover:tw-border-[2px] tw-transition-all tw-duration-200 tw-flex tw-items-center tw-space-x-3 tw-border-solid"
                :class="{
                  'tw-border-2 tw-border-black': selectedTypeOfStudy === studyTypes[0],
                  'tw-border-white-two tw-border-2': selectedTypeOfStudy !== studyTypes[0],
                }"
                @click="changeSelectedTypeOfStudy(studyTypes[0])"
              >
                <img
                  :src="wizardIcon"
                  alt=""
                >
                <span class="tw-mt-1">
                  Study Creation Wizard - Simply name your objective
                </span>
              </div> -->
              <div
                class="tw-p-5 tw-cursor-pointer tw-rounded-xl tw-mt-3 hover:tw-border-black hover:tw-border-[2px] tw-transition-all tw-duration-200 tw-flex tw-items-center tw-space-x-3 tw-border-solid"
                :class="{
                  'tw-border-2 tw-border-black': selectedTypeOfStudy === studyTypes[1],
                  'tw-border-white-two tw-border-2': selectedTypeOfStudy !== studyTypes[1],
                }"
                @click="changeSelectedTypeOfStudy(studyTypes[1])"
              >
                <span>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.57 4.23998C17.87 5.50998 17.68 6.95998 17.57 8.28998C17.53 8.81998 17.51 9.32998 17.57 9.77998C17.62 10.08 17.71 10.36 17.92 10.57C18.13 10.78 18.41 10.87 18.71 10.92C19.16 10.99 19.67 10.97 20.2 10.92C21.53 10.82 22.98 10.63 24.25 10.92C25.67 11.26 26.86 11.82 27.62 12.85C28.36 13.86 28.73 15.36 28.32 17.74C27.49 22.52 23.27 28 15.67 28C11.48 28 7.68 26.3 4.94 23.55C2.2 20.8 0.5 17.01 0.5 12.82C0.5 5.21998 5.91 1.00998 10.76 0.169977C13.14 -0.240023 14.64 0.129977 15.65 0.869977C16.68 1.62998 17.23 2.81998 17.58 4.23998H17.57ZM15.62 4.70998C15.4 3.78998 15.12 2.96998 14.45 2.47998C13.76 1.96998 12.71 1.85998 11.09 2.13998C7.02 2.84998 2.5 6.41998 2.5 12.82C2.5 16.46 3.98 19.75 6.36 22.14C8.75 24.53 12.04 26 15.68 26C22.07 26 25.66 21.43 26.36 17.4C26.64 15.77 26.53 14.73 26.02 14.04C25.53 13.37 24.72 13.1 23.79 12.87C22.46 12.55 20.91 12.92 19.59 12.95C18.35 12.98 17.28 12.74 16.52 11.98C15.76 11.22 15.52 10.15 15.55 8.90998C15.58 7.58998 15.95 6.02998 15.63 4.70998H15.62ZM21.21 17.89C22.58 19.26 22.58 21.47 21.21 22.84C19.84 24.2 17.63 24.2 16.26 22.84C14.89 21.47 14.89 19.26 16.26 17.89C17.63 16.52 19.84 16.52 21.21 17.89ZM19.8 19.3C19.22 18.72 18.26 18.72 17.68 19.3C17.09 19.89 17.09 20.84 17.68 21.42C18.26 22.01 19.22 22.01 19.8 21.42C20.39 20.84 20.39 19.88 19.8 19.3ZM10.61 7.27998C11.98 8.64998 11.98 10.86 10.61 12.23C9.24 13.6 7.03 13.6 5.66 12.23C4.29 10.86 4.29 8.64998 5.66 7.27998C7.03 5.90998 9.24 5.90998 10.61 7.27998ZM9.2 8.68998C8.61 8.09998 7.66 8.09998 7.08 8.68998C6.5 9.27998 6.5 10.23 7.08 10.81C7.67 11.4 8.62 11.4 9.2 10.81C9.78 10.22 9.79 9.26998 9.2 8.68998ZM12.39 16.12C13.76 17.49 13.76 19.7 12.39 21.07C11.02 22.44 8.81 22.44 7.44 21.07C6.07 19.7 6.07 17.49 7.44 16.12C8.81 14.75 11.02 14.75 12.39 16.12ZM10.98 17.53C10.4 16.95 9.44 16.95 8.86 17.53C8.27 18.12 8.27 19.07 8.86 19.65C9.44 20.24 10.4 20.24 10.98 19.65C11.57 19.07 11.57 18.11 10.98 17.53Z"
                      fill="black"
                    />
                  </svg>

                </span>
                <span>
                  Build Your Own - Start from scratch
                </span>
              </div>
              <template v-if="templatesList.length">
                <text-divider
                  text="or select a template"
                  class="tw-mt-3"
                />
                <div
                  class="flex space-x-1 tw-mt-3 tw-text-black-three tw-overflow-x-auto"
                >
                  <span
                    v-for="tab in tabsList"
                    :key="tab"
                    :class="[
                      {
                        'py-4 px-3 cursor-pointer w-full text-center': true,
                        'border-bottom-2-primary tw-text-black': tab === activeTab,

                        'tw-text-black-three hover:tw-text-black tw-transition-all tw-duration-300':
                          tab !== activeTab,
                      },
                    ]"
                    @click="changeActiveTab(tab)"
                  >{{ tab }}</span>
                </div>
                <div
                  v-for="template in templatesList"
                  :key="template.headline"
                >
                  <div
                    v-if="selectedTemplateId=== template.id"
                    class="tw-border-[3px] tw-border-solid tw-overflow-hidden tw-border-territory-dark  tw-rounded-xl tw-mt-3"
                  >
                    <div class="tw-bg-light-four tw-p-5">
                      <span class="tw-font-semibold">
                        {{ template.name }}
                      </span>
                    </div>
                    <div class="tw-border-t tw-py-5 tw-border-solid tw-border-white-two">
                      <!-- <div class="tw-px-5 ">
                      <p class="tw-text-sm tw-text-black-three">
                        Question
                      </p>
                    <div class="tw-flex tw-space-x-3">
                      <img
                        src="https://via.placeholder.com/64"
                        class="tw-rounded-xl"
                        alt=""
                      >
                      <p>
                        Could you provide me with feedback on my recent project/task performance?
                      </p>
                    </div>
                    </div> -->
                      <div
                        v-for="(question, index) in template.questions"
                        :key="`${index}-question`"
                        class="tw-px-5 tw-py-1"
                      >
                        <p class="tw-text-sm tw-text-black-three">
                          Question
                        </p>
                        <div class="tw-flex tw-space-x-3 tw-items-center">
                          <img
                            src="https://via.placeholder.com/64"
                            class="tw-rounded-xl"
                            alt=""
                          >
                          <p>
                            {{ question.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="tw-my-2"
                  >
                    <p
                      class="tw-rounded-xl tw-cursor-pointer hover:tw-border-black hover:tw-border-[2px] tw-transition-all tw-duration-200 tw-p-5 tw-border tw-border-solid tw-border-white-two"
                      @click="selectTemplate(template.id)"
                    >
                      {{ template.name }}
                    </p>
                  </div>
                </div>
              </template>
              <!-- <div class="tw-mt-3 tw-flex tw-flex-col tw-space-y-3">
                <p
                  v-for="template in templatesList"
                  :key="template.name"
                  class="tw-rounded-xl tw-cursor-pointer hover:tw-border-black hover:tw-border-[2px] tw-transition-all tw-duration-200 tw-p-5 tw-border tw-border-solid tw-border-white-two"
                >
                  {{ template.name }}
                </p>
              </div> -->
              <div class="tw-mt-3 tw-flex tw-justify-between tw-items-center">
                <ah-button
                  size="large"
                  type="outlined"
                  @click="goBack"
                >
                  Go back
                </ah-button>
                <ah-button
                  size="large"
                  :disabled="!selectedTypeOfStudy"
                  @click="startBuildingHandler"
                >
                  Start building
                </ah-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- IF BUILD YOUR OWN IS SELECTED -->
      <div
        v-if="(selectedTypeOfStudy==studyTypes[1] && currentStep == 2) || (selectedTypeOfStudy==studyTypes[1] && currentStep == 3) || (selectedTypeOfStudy==studyTypes[2] && currentStep == 3) || (selectedTypeOfStudy==studyTypes[0] && currentStep==2) || (selectedTypeOfStudy==studyTypes[0] && currentStep==3)"
        class="tw-flex tw-w-full"
      >
        <study-sidebar
          :current-step="1"
          :survey-id="survey.id"
          :survey="survey"
        />
        <div
          v-if="!isStaticStudy && !isVideoStudy && !survey.headline"
          class="tw-px-20 tw-mt-8  tw-w-full tw-max-h-[92vh] fade-in tw-overflow-auto scrollbar-track-gray-400 scrollbar-hover-black scrollbar-thumb-dark-gray-400 scrollbar-thin"
        >
          <study-information :survey="survey" />
          <div
            class="tw-h-[75vh] tw-flex tw-flex-col tw-justify-center tw-items-center"
          >
            <div class="tw-mt-20">
              <p class="tw-font-semibold tw-text-2xl tw-text-center tw-max-w-md tw-mx-auto">
                How would you like to introduce this study to your respondents?
              </p>
            </div>
            <div class="tw-mt-5 tw-flex tw-justify-center tw-space-x-3">
              <div
                class="tw-flex tw-flex-col tw-justify-start tw-cursor-pointer tw-items-center tw-bg-white-one hover:tw-bg-[#FCF6EC] tw-px-12 tw-py-4 tw-rounded-[20px] tw-min-w-[300px]"
                @click="changeToStaticOrVideo('static')"
              >
                <div class="tw-border-[0.5px] tw-border-[#C9C9C9] tw-w-5 tw-h-5 tw-bg-transparent tw-border-solid tw-rounded-full" />
                <div class="tw-px-10 tw-py-14 tw-flex tw-flex-col tw-space-y-8 tw-items-center tw-justify-center">
                  <p class="tw-mt-6 tw-text-2xl">
                    Static Message
                  </p>
                  <div
                    class="tw-w-[80px] tw-h-[80px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-b tw-from-[#5484FF] tw-to-[#CC8BFF]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="60"
                      viewBox="0 0 65 60"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_838_2393)">
                        <path
                          d="M4.5 18C6.71 18 8.5 16.21 8.5 14V12H19.5V48H15.5C13.29 48 11.5 49.79 11.5 52C11.5 54.21 13.29 56 15.5 56H31.5C33.71 56 35.5 54.21 35.5 52C35.5 49.79 33.71 48 31.5 48H27.5V12H38.5V14C38.5 16.21 40.29 18 42.5 18C44.71 18 46.5 16.21 46.5 14V10C46.5 6.69 43.81 4 40.5 4H6.5C3.19 4 0.5 6.69 0.5 10V14C0.5 16.21 2.29 18 4.5 18Z"
                          fill="white"
                        />
                        <path
                          d="M60.5 0C58.29 0 56.5 1.79 56.5 4V56C56.5 58.21 58.29 60 60.5 60C62.71 60 64.5 58.21 64.5 56V4C64.5 1.79 62.71 0 60.5 0Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_838_2393">
                          <rect
                            width="64"
                            height="60"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                class="tw-flex tw-flex-col tw-justify-start tw-cursor-pointer tw-items-center tw-bg-white-one hover:tw-bg-[#FCF6EC] tw-px-12 tw-py-4 tw-rounded-[20px] tw-min-w-[300px]"
                @click="changeToStaticOrVideo('video')"
              >
                <div class="tw-border-[0.5px] tw-border-[#C9C9C9] tw-w-5 tw-h-5 tw-bg-transparent tw-border-solid tw-rounded-full" />
                <div class="tw-px-10 tw-py-14 tw-flex tw-flex-col tw-space-y-8 tw-items-center tw-justify-center">
                  <p class="tw-mt-6 tw-text-2xl">
                    Video
                  </p>
                  <div
                    class="tw-w-[80px] tw-h-[80px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-b tw-from-[#FF5454] tw-to-[#FFB58B]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="73"
                      viewBox="0 0 67 73"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_838_59)">
                        <path
                          d="M60.0059 23.407L51.9397 28.0518C49.4035 20.3278 42.1218 14.7435 33.5327 14.7435H21.0086C10.3017 14.7435 1.62109 23.407 1.62109 34.0928V39.938C1.62109 50.6238 10.3017 59.2872 21.0086 59.2872H33.5327C42.1218 59.2872 49.4035 53.703 51.9397 45.9789L60.0059 50.6238C63.8494 52.8418 67 51.0282 67 46.5921V27.4517C67 23.0156 63.8494 21.202 60.0059 23.42V23.407Z"
                          fill="white"
                        />
                        <path
                          d="M4.13096 14.1563C16.7596 1.65693 37.2192 1.74826 49.7433 14.352C50.2139 14.8217 50.8414 15.0696 51.4689 15.0696C52.0964 15.0696 52.6978 14.8348 53.1684 14.3651C54.1228 13.4256 54.1228 11.8991 53.1815 10.9467C38.7749 -3.56202 15.2432 -3.65335 0.718864 10.7118C-0.235478 11.6512 -0.235478 13.1777 0.70579 14.1302C1.64706 15.0827 3.17662 15.0827 4.13096 14.1433V14.1563Z"
                          fill="white"
                        />
                        <path
                          d="M49.7433 58.6609C37.2192 71.2647 16.7596 71.343 4.13096 58.8567C3.17662 57.9172 1.64706 57.9303 0.70579 58.8697C-0.235478 59.8222 -0.235478 61.3487 0.718864 62.2881C7.93525 69.4381 17.3872 73 26.8391 73C36.291 73 45.939 69.3467 53.1815 62.0663C54.1228 61.1138 54.1228 59.5873 53.1684 58.6479C52.2141 57.7085 50.6845 57.7215 49.7433 58.6609Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_838_59">
                          <rect
                            width="67"
                            height="73"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isStaticStudy || isVideoStudy || survey.headline"
          class="tw-pt-10 tw-pb-12 tw-px-5 xl:tw-px-10 2xl:tw-px-20 tw-h-full tw-w-full fade-in tw-max-h-[92vh] tw-overflow-auto scrollbar-track-gray-400 scrollbar-hover-black scrollbar-thumb-dark-gray-400 scrollbar-thin"
        >
          <div
            class="tw-h-full tw-mx-auto"
            :class="isStaticStudy || survey.headline && !isVideoStudy ? 'tw-max-w-[550px] ': 'tw-w-full'"
          >
            <study-information :survey="survey" />
            <div
              v-if="isVideoStudy"
              class="tw-w-full  tw-h-full"
            >
              <div

                class="tw-flex tw-flex-col tw-justify-center tw-h-full"
              >
                <div class=" tw-h-full tw-flex tw-flex-col tw-justify-center tw-mx-auto md:tw-w-full md:tw-max-w-lg">
                  <div class="tw-mt-5 tw-flex tw-justify-center tw-space-x-3">
                    <button
                      class=" tw-px-3 tw-py-2 tw-rounded-lg"
                      :class="{
                        'tw-bg-territory tw-text-white': activeButton === 'record',
                        'tw-bg-white tw-border tw-border-white-two tw-border-solid': activeButton !== 'record',
                      }"
                      @click="toggleActiveButton('record')"
                    >
                      Record video
                    </button>
                    <button
                      class=" tw-px-3 tw-py-2 tw-rounded-lg "
                      :class="{
                        'tw-bg-territory tw-text-white': activeButton === 'upload',
                        'tw-bg-white tw-border tw-border-white-two tw-border-solid': activeButton !== 'upload',
                      }"
                      @click="toggleActiveButton('upload')"
                    >
                      Upload video
                    </button>
                  </div>
                  <div
                    v-if="activeButton=='record'"
                    class="fade-in"
                  >
                    <p class="tw-text-2xl tw-font-semibold tw-text-center tw-mt-3">
                      Record a 30 seconds video introduction
                    </p>
                    <p class="tw-mt-3 tw-text-center tw-px-3 tw-text-base">
                      Before participants begin the survey, they will encounter this initial screen. Create a welcoming video that provides participants with a warm welcome message. After pressing “start recording” you will have 5 seconds to start recording.
                    </p>
                    <div v-if="!permissionsGranted">
                      <p
                        v-if="errorMessage"
                        class="tw-text-error tw-mt-5"
                      >
                        {{ errorMessage }}
                      </p>
                      <div
                        v-else
                        class="tw-mt-5"
                      >
                        <p
                          class="tw-text-center "
                        >
                          To begin please allow access to camera & mic.
                        </p>
                        <div class="tw-mt-5 tw-text-center">
                          <ah-button
                            type="secondary"
                            @click="showTempVideoRecorder"
                          >
                            Allow access to camera & mic
                          </ah-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="activeButton=='upload'"
                    class="fade-in"
                  >
                    <p class="tw-text-2xl tw-font-semibold tw-text-center tw-mt-3">
                      Upload a 30 seconds video introduction
                    </p>
                    <p class="tw-mt-3 tw-text-center tw-px-3 tw-text-base">
                      Before participants begin the survey, they will encounter this initial screen. Create a welcoming video that provides participants with a warm welcome message.
                    </p>
                    <div
                      v-if="showUploadVideoInput && !showRecordedVideo"
                      class="tw-text-center tw-cursor-pointer tw-border tw-border-dashed tw-border-primary tw-pt-10 tw-pb-12 tw-rounded-[20px] tw-mt-3"
                      @click="openFileInput"
                      @dragover.prevent="handleDragOver"
                      @drop.prevent="handleDrop"
                    >
                      <p class="tw-text-primary tw-text-xl ">
                        Drop your video here or click
                      </p>
                      <p>
                        Supported files mp4, avi and WMV. Max file size 300MB
                      </p>
                      <input
                        ref="fileInput"
                        type="file"
                        style="display: none;"
                        accept=".mp4,.avi,.wmv"
                        @change="handleFileChange"
                      >
                    </div>
                  </div>
                </div>

                <video-camera-reflection
                  v-if="isParentLoaded && showTempVideo && !showFullVideoRecorder && permissionsGranted"
                />
                <video-recording
                  v-if="isParentLoaded && showFullVideoRecorder && !showTempVideo && permissionsGranted"

                  :show-camera="true"
                  :full-recorder="true"
                  @save="saveVideo"
                  @cancel="cancelRecording"
                />
                <!-- :url="survey.introVideoUrl || uploadedVideoURL" -->
                <video-recording-player
                  v-if="showRecordedVideo"
                  :url="surveyIntroVideo"
                  :is-uploaded-video="isUploadVideo"
                  :chunks="recordedChunks"
                  :seconds="recordedVideoSeconds"
                />
                <div class="tw-flex tw-space-x-2 tw-justify-between tw-w-full tw-pb-4 tw-mb-2">
                  <div>
                    <ah-button
                      type="outlined"
                      @click="changeToStaticOrVideo('static')"
                    >
                      Change to static message
                    </ah-button>
                  </div>
                  <div class="tw-flex tw-space-x-2">
                    <ah-button
                      v-if="showRecordedVideo && activeButton=='record'"
                      type="secondary"
                      @click="showTempVideoRecorder"
                    >
                      Record again
                    </ah-button>
                    <ah-button
                      v-else-if="showRecordedVideo && activeButton=='upload'"
                      type="secondary"
                      @click="showUploadVideoInput = true ; showRecordedVideo = false"
                    >
                      Upload again
                    </ah-button>
                    <ah-button
                      v-if="showTempVideo"
                      @click="recordActualVideo"
                    >
                      Start recording
                    </ah-button>
                    <ah-button
                      v-if="showRecordedVideo"
                      @click="uploadAndContinue"
                    >
                      Next step
                    </ah-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isStaticStudy || survey.headline && !isVideoStudy"
              class="tw-flex tw-flex-col tw-justify-center tw-h-full fade-in tw-mx-auto"
            >
              <div class=" tw-h-full tw-flex tw-flex-col tw-justify-center">
                <div>
                  <p class="tw-text-2xl tw-font-semibold tw-text-center">
                    Set a welcome message
                  </p>
                  <p class="tw-mt-3 tw-text-center tw-px-3 tw-text-base">
                    Before participants begin the survey, they will encounter this initial screen. Create a welcoming page that provides participants with a warm welcome message.
                  </p>
                  <div class=" mt-4">
                    <st-upload-preview
                      v-if="surveyInfo.pageImageUrl"
                      key="image-preview"
                      :disabled="isEditingDisabled"
                      :display-large-preview="true"
                      :filename="coverPhotoFileName"
                      :hide-bar="false"
                      :src="surveyInfo.pageImageUrl"
                      @remove="removeImagePreview"
                    />
                    <ah-button
                      v-else
                      type="secondary"
                      :disabled="isEditingDisabled"
                      @click="showCoverPhotoModal = true"
                    >
                      <span class="flex items-center space-x-2">

                        <span>Add an image</span>
                      </span>
                    </ah-button>
                  </div>
                  <div class="tw-flex tw-w-full tw-mx-auto tw-flex-col tw-space-y-1 tw-mt-5">
                    <label class="">Primary heading</label>
                    <div class="">
                      <textarea
                        v-model="surveyInfo.headline"
                        class="my-1 w-full tw-rounded-lg px-2 py-1 tw-text-base"
                        :disabled="isEditingDisabled"
                        rows="4"
                        @blur="updateSurvey"
                      />
                      <div class="flex space-x-3 items-center">
                        <span class="tw-text-black-three">
                          Don’t provide any information about incentives.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex space-x-2 justify-between  tw-mb-8">
                <ah-button
                  type="outlined"
                  @click="changeToStaticOrVideo('video')"
                >
                  Change to video
                </ah-button>
                <ah-button
                  class="bg-blue-primary p-3   rounded-4p  text-white"
                  @click="handleSave"
                >
                  Next Step
                </ah-button>
              </div>
            </div>
          </div>
        </div>

        <!-- IF WIZARD IS SELECTED -->

        <!-- SHOW MOBILE PREVIEW -->
      </div>

      <wizard
        v-if="selectedTypeOfStudy==studyTypes[0] && currentStep == 1"
        :survey="survey"
        @back="goBackToInitialStep"
        @hidePreview="showMobilePreview=false"
        @showPreview="showMobilePreview=true"
      />
      <div
        v-if="showMobilePreview || survey.headline && currentStep == 2"
        class="tw-w-full tw-flex-grow-0 tw-h-full tw-max-h-full tw-max-w-[450px] xl:tw-max-w-[500px] 2xl:tw-max-w-[550px]"
      >
        <phone-preview
          :survey="surveyInfo"
          :step="1"
        />
      </div>
      <!-- EXTRA STUFF LIKE MODALS -->
      <!-- :aspect-ratio="4.23" -->
      <st-media-uploader
        v-if="showCoverPhotoModal"
        :folders="folders"
        modal-title="Add Invitation Page Cover Photo"
        :aspect-ratio="NaN"
        @closeMediaUploader="showCoverPhotoModal = false"
        @uploadComplete="uploadCoverPhoto"
      />
      <st-modal
        :show-modal="showPreviewModal"
        @closeModal="showPreviewModal = false"
      >
        <template slot="modalTitle">
          This is a preview
        </template>
        <template slot="additionalInformation">
          <st-participant-invite
            v-if="showPreviewModal"
            :survey="surveyInfo"
            :invitation-preview="true"
            @closeModal="showPreviewModal = false"
          />
        </template>
      </st-modal>
    </div>

    <!-- <div
      class=" bx--grid tw-hidden tw-flex-col tw-h-full "
    >
      <p class="tw-text-2xl tw-font-semibold tw-text-center">
        Let’s begin recording a popup wave
      </p>
      <p class="tw-mt-3 tw-text-center">
        This will be a video without audio to capture the attention of the
        users. It can have a maximum duration of 10 seconds.
      </p>
      <p class="tw-mt-3 tw-text-center">
        Before starting you must grant access to the camera and microphone.
      </p>
      <video-recording
        v-if="isParentLoaded"
        :url="survey.introVideoUrl"
        @save="saveVideo"
      />
      <div class="tw-flex tw-w-full md:tw-w-1/2 tw-mx-auto tw-flex-col tw-space-y-1 tw-mt-10">
        <label class="font-600">Some content to motivate users to answer the study*</label>
        <st-input
          v-model="surveyInfo.headline"
          :disabled="isEditingDisabled"
          style-type="rounded"
          placeholder="Your title goes here, be brief and welcoming"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import wizardIcon from '../../../../assets/images/wizard_icon.png'
import {
  AhButton,
  PhonePreview,
  StInlineSpinner,
  StInput,
  StMediaUploader,
  StModal,
  StTextArea,
  StToastNotification,
  StUploadPreview,
  TextDivider
} from '../../common'
import isEditingDisabled from './isEditingDisabled'
import saveUpdates from './saveUpdates'
import Wizard from './wizard.vue'

import { DirectUpload } from '@rails/activestorage'
import { mapState } from 'vuex'
import StParticipantInvite from '../participants/participant-invite'
import PreviewBar from './preview-bar.vue'
import StudyInformation from './study-information.vue'
import StudySidebar from './study-sidebar.vue'
import VideoCameraReflection from './video-camera-reflection.vue'
import VideoRecordingPlayer from './video-recording-player.vue'
import VideoRecording from './video-recording.vue'
export default {
  components: {
    StudySidebar,
    PhonePreview,
    PreviewBar,
    VideoRecording,
    StInlineSpinner,
    StudyInformation,
    StInput,
    StMediaUploader,
    StTextArea,
    VideoCameraReflection,
    AhButton,
    TextDivider,
    StToastNotification,
    StUploadPreview,
    StModal,
    StParticipantInvite,
    VideoRecordingPlayer,
    Wizard
  },
  mixins: [isEditingDisabled, saveUpdates],
  props: {
    folders: {
      type: Array,
      required: false,
      default: () => []
    },
    survey: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false

    }
  },
  data: function () {
    return {
      studyTypes: ['wizard', 'default', 'template'],
      currentStep: 0,
      showCoverPhotoModal: false,
      showUploadVideoInput: false,
      surveyInfo: this.survey,
      url: '/study/' + this.survey.id,
      showPreviewModal: false,
      showMobilePreview: false,
      isParentLoaded: false,
      showTempVideo: false,
      recordedVideoSeconds: 0,
      showFullVideoRecorder: false,
      permissionsGranted: false,
      errorMessage: null,
      recordedChunks: [],
      showRecordedVideo: false,
      isUploadVideo: false,
      selectedTypeOfStudy: 'default',
      isStaticStudy: false,
      templatesList: [],
      activeButton: 'record',
      selectedTemplateId: null,
      uploadedVideoURL: null,
      showToast: false,
      isVideoStudy: false,
      tabsList: ['All Templates', 'Saved Templates'],
      activeTab: 'All Templates',
      wizardIcon: wizardIcon

    }
  },
  computed: {
    ...mapState(['directUploadUrl']),

    surveyIntroVideo() {
      return this.survey.introVideoMp4Url ? this.survey.introVideoMp4Url : this.survey.introVideoUrl;
    },

    isStudyBeingCreated () {
      return this.survey.surveyType === 'study'
    },

    backURL () {
      return `/study/${this.survey.id}/edit`
    },
    coverPhotoFileName () {
      return this.surveyInfo.pageImageUrl
        .split('/')
        .slice(-1)
        .pop()
    },
    buttonDisabled () {
      return this.fieldsEmpty || this.isEditingDisabled
    },
    fieldsEmpty () {
      return !(this.surveyInfo['greeting'] && this.surveyInfo['headline'])
    }
  },
  mounted () {
    this.$store.commit('setCurrentUser', this.user)

    if (this.survey.isUploadVideo) {
      this.activeButton = 'upload'
      this.showUploadVideoInput = true
      this.isUploadVideo = true
    }
    // if questions are already added
    if (this.survey.questions.length) {
      this.currentStep = 2
    }
    if (Object.values(this.survey).length) {
      this.isParentLoaded = true
    }
    if (this.survey.introVideoUrl) {
      this.isVideoStudy = true
      this.showRecordedVideo = true
      this.isStaticStudy = false
      this.currentStep = 3
      this.showMobilePreview = false
    }
    // if the template is being used for create study
    if (this.survey.creationMethod == 'template') {
      this.selectedTypeOfStudy = this.studyTypes[2]
      if (this.survey.introMethod == 'video') {
        this.isVideoStudy = true
        this.isStaticStudy = false
        this.currentStep = 3
        this.showMobilePreview = false
      } else if (this.survey.introMethod == 'static') {
        this.isStaticStudy = true
        this.isVideoStudy = false
        this.currentStep = 3
        this.showMobilePreview = true
      }
    }

    // if building from scratch
    if (this.survey.creationMethod == 'scratch') {
      this.selectedTypeOfStudy = this.studyTypes[1]
      if (this.survey.introMethod == 'video') {
        this.isVideoStudy = true
        this.isStaticStudy = false
        this.currentStep = 3
        this.showMobilePreview = false
      } else if (this.survey.introMethod == 'static') {
        this.isStaticStudy = true
        this.isVideoStudy = false
        this.currentStep = 3
        this.showMobilePreview = true
      } else {
        this.currentStep = 2
      }
    }

    // if wizard is being used for create study
    else if (this.survey.creationMethod == 'wizard') {
      this.selectedTypeOfStudy = this.studyTypes[0]
      if (this.survey.introMethod == 'video' && this.survey.questions.length) {
        this.isVideoStudy = true
        this.isStaticStudy = false
        this.currentStep = 3
        this.showMobilePreview = false
      } else if (this.survey.introMethod == 'static' && this.survey.questions.length) {
        this.isStaticStudy = true
        this.isVideoStudy = false
        this.currentStep = 3
        this.showMobilePreview = true
      } else if (this.survey.questions.length) {
        this.currentStep = 2
      } else {
        this.currentStep = 0
      }
    }

    setTimeout(() => {
      this.getStudiesForTemplates()
    }, 200)
    // console.log(this.survey)
    // this.$store.commit('setCurrentUser', this.user)
  },
  methods: {

    selectTemplate (templateId) {
      this.selectedTemplateId = templateId
      this.selectedTypeOfStudy = this.studyTypes[2]
    },
    showTempVideoRecorder () {
      this.getCameraMicPermissions()
      this.showRecordedVideo = false
      this.showFullVideoRecorder = false
      this.showTempVideo = true
    },
    recordActualVideo () {
      this.recordedVideoSeconds = 0
      this.showTempVideo = false
      this.showRecordedVideo = false
      this.showFullVideoRecorder = true
    },
    async getCameraMicPermissions () {
      try {
        // Check if the user has already granted permission for the media devices
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        this.permissionsGranted = true
        // Permission already granted, initialize the recorder
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.showError = true
          this.errorMessage = 'Please allow access to camera and microphone by going to browser settings'
          // Handle the case where permission is not granted
        } else {
          console.error('Error accessing camera and microphone:', error)
        }
      }
    },

    openFileInput () {
      // Trigger a click event on the hidden file input element
      this.$refs.fileInput.click()
    },
    handleFileChange (event, type) {
      let selectedFile
      if (type == 'dragged') {
        selectedFile = event.dataTransfer.files[0]
      } else {
        // Handle the selected file here
        selectedFile = event.target.files[0]
      }
      if (selectedFile) {
        // You can access the selected file using 'selectedFile'
        this.$store.commit('toggleFullSpinner', true)
        this.buildRecordData(selectedFile).then((recordData) => {
          recordData.append('survey[is_upload_video]', true)
          return this.$axios.request({
            method: 'patch',
            url: this.url,
            data: recordData
          })
        }).then((res) => {
          // this.$store.commit('toggleFullSpinner', false)
          window.location.reload()
          // console.log('res', res.data.introVideoUrl)
          this.showRecordedVideo = true
          this.survey.introVideoUrl = res.data.introVideoUrl
          this.uploadedVideoURL = res.data.introVideoUrl
          this.showUploadVideoInput = false
        })
          .catch((err) => {
            this.$store.commit('toggleFullSpinner', false)
            console.log('err', err)
          })

        // You can now perform further actions, such as uploading the file to a server.
      }
    },
    handleDragOver (event) {
      // Prevent the default dragover behavior
      event.preventDefault()
    },
    handleDrop (event) {
      // Prevent the default drop behavior
      event.preventDefault()

      // Access the dropped files
      const droppedFiles = event.dataTransfer.files

      // You can handle the dropped files here
      if (droppedFiles.length > 0) {
        // Do something with the dropped files
        this.handleFileChange(event, 'dragged')
        console.log('Dropped Files:', droppedFiles)
      }
    },
    toggleActiveButton (button) {
      if (button == 'upload') {
        this.showUploadVideoInput = true
      }
      this.activeButton = button
      this.showRecordedVideo = false
      this.showTempVideo = false
      this.showFullVideoRecorder = false
      this.recordedChunks = []
    },
    goToStepTwo () {
      this.currentStep = 2
    },
    goToWizardStepTwo () {
      this.showMobilePreview = true
    },
    changeToStaticOrVideo (type) {
      if (type === 'video') {
        this.isVideoStudy = true
        this.isStaticStudy = false
        this.showMobilePreview = false
        this.updateSurveyData({
          intro_method: 'video'
        })
      } else if (type === 'static') {
        this.isStaticStudy = true
        this.showMobilePreview = true
        this.isVideoStudy = false
        this.updateSurveyData({
          intro_method: 'static'
        })
      }
      this.currentStep = 3
    },
    startBuildingHandler () {
      if (this.selectedTypeOfStudy === this.studyTypes[0]) {
        this.updateSurveyData({
          creation_method: 'wizard'
        })
        this.currentStep = 1
        this.showMobilePreview = false
      }
      if (this.selectedTypeOfStudy === this.studyTypes[1]) {
        this.updateSurveyData({
          creation_method: 'scratch'
        })
        this.currentStep = 2
        this.showMobilePreview = false
      }
      if (this.selectedTemplateId && this.selectedTypeOfStudy === this.studyTypes[2]) {
        this.updateSurveyData({
          creation_method: 'template'
        })

        this.$axios.request({
          method: 'post',
          url: `/study/${this.survey.id}/clone_survey_and_associated_data`,
          data: { template_id: this.selectedTemplateId }
        })
          .then((res) => {
            if (res.status === 200) {
              window.location = `/study/${this.survey.id}/edit/content`
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    changeActiveTab (tab) {
      this.activeTab = tab
    },
    goBackToInitialStep () {
      this.currentStep = 0
      this.showMobilePreview = false
    },
    toggleMobilePreview () {
      this.showMobilePreview = !this.showMobilePreview
    },
    changeSelectedTypeOfStudy (type) {
      this.selectedTypeOfStudy = type
      this.selectedTemplateId = null
    },
    goBack () {
      window.location = `/study/${this.survey.id}/edit`
    },
    removeImagePreview () {
      let deleteUrl = `/study/${this.survey.id}/`
      let property = 'survey[page_image]'
      this.$axios.request({
        method: 'delete',
        url: deleteUrl,
        data: { property }
      })
        .then(res => { this.surveyInfo.pageImageUrl = null })
        .catch(err => { console.log(err) })
    },

    saveVideo (chunks, totalSec) {
      this.showFullVideoRecorder = false
      this.recordedVideoSeconds = totalSec
      this.recordedChunks = chunks
      setTimeout(() => {
        this.showRecordedVideo = true
        this.isUploadVideo = false
      }, 400)
    },
    buildDirectUpload () {
      if (this.recordedChunks.length === 0) return Promise.resolve()
      const blob = new Blob(this.recordedChunks, { type: 'video/webm' })
      const file = new File([blob], 'blob', { type: 'video/webm' })
      return this.buildRecordData(file)
        .then((recordData) => {
          recordData.append('survey[is_upload_video]', false)
          return this.$axios.request({
            method: 'patch',
            url: this.url,
            data: recordData
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    },

    buildRecordData (file) {
      let updateRecordData = new FormData()
      return this.directUpload(file)
        .then((signedId) => {
          updateRecordData.append('survey[intro_video]', signedId)
          return updateRecordData
        })
        .catch((err) => {
          console.log(err)
        })
    },

    directUpload (uploadFile) {
      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(uploadFile, this.directUploadUrl)
        upload.create((error, blob) => {
          error ? reject(error) : resolve(blob.signed_id)
        })
      })
    },

    saveAndPreview () {
      this.$store.commit('toggleFullSpinner', true)
      this.updateSurvey()
        .then(() => {
          window.location = `/study/${this.survey.id}/preview`
        })
        .catch(() => {
          window.location = `/study/${this.survey.id}/preview`
        })
    },
    updateSurvey () {
      return this.$axios
        .request({
          method: 'patch',
          url: this.url,
          data: {
            survey: {
              headline: this.surveyInfo.headline,
              greeting: this.surveyInfo.greeting
            }
          }
        })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateSurveyData (data) {
      return this.$axios
        .request({
          method: 'patch',
          url: this.url,
          data: {
            survey: { ...data }
          }
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateAndRoute (nextRoute) {
      this.$store.commit('toggleFullSpinner', true)
      this.updateSurvey()
        .then((res) => {
          console.log('res', res)
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    saveDraft () {
      if (this.recordedChunks.length) {
        this.buildDirectUpload().then(() => {
          this.saveProgress()
        })
      }
    },
    handleSave () {
      this.saveAndToHub('content')
    },
    getStudiesForTemplates () {
      let url = `/study?is_template=true`
      this.$axios.request({
        method: 'get',
        url: url
      })
        .then((res) => {
          this.templatesList = res.data.surveys
        })
        .catch((err) => {
          console.log(err)
        })
    },
    uploadAndContinue () {
      this.$store.commit('toggleFullSpinner', true)

      this.buildDirectUpload().then(() => {
        this.$store.commit('toggleFullSpinner', false)

        this.saveAndToHub('content')
      }).catch((err) => {
        this.$store.commit('toggleFullSpinner', false)
        console.log(err)
      })
    },
    uploadCoverPhoto (payload) {
      let formData = new FormData()
      formData.append('survey[page_image]', payload)

      this.$axios
        .request({
          method: 'patch',
          url: this.url,
          data: formData
        })
        .then((res) => {
          this.surveyInfo.pageImageUrl = res.data.pageImageUrl
          this.showCoverPhotoModal = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancelRecording () {
      this.showFullVideoRecorder = false
    }
  }
}
</script>
