<template>
  <div
    v-if="survey.surveyType=='study'"
    class="w-full  flex-col space-y-5 md:flex-row md:space-y-0 md:tw-space-x-5 flex mt-5"
  >
    <!-- <div class=" mt-3">
              Study URL
              <st-tooltip
                class="st-edit-audience__anonymous-tooltip"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 411 : 250"
                tip="Copy study URL to share as link to others"
              >
                <icon-wrapper
                  icon-name="info"
                />
              </st-tooltip>
            </div> -->
    <div class="flex flex-col  px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
      <div>
        <p class="font-bold font-18">
          Study URL
        </p>
        <p class="mt-2">
          You can share this url with the list of users who wish to complete this study
        </p>
        <p class="text-primary-brown mt-2">
          {{ getStudyURL }}
        </p>
      </div>

      <button
        v-if="!copied"
        class="px-3 py-2 bg-white w-full mt-5 md:fit-content rounded-4p border-gray-100"
        @click="copyToClipboard"
      >
        Copy URL
      </button>

      <p
        v-else
        class="bg-success flex space-x-2 items-center px-3 py-2 rounded-4p disabled"
      >
        <span><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 0.875C10.3829 0.875 13.125 3.61729 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61729 13.125 0.875 10.3827 0.875 7C0.875 3.61729 3.61729 0.875 7 0.875ZM3.96779 7.08002C3.74167 6.82989 3.76111 6.44395 4.01078 6.21806C4.26091 5.99193 4.64685 6.01137 4.87275 6.26104L6.39845 7.94679L9.69542 4.54122C9.93092 4.29909 10.3175 4.29383 10.5597 4.52933C10.8018 4.76483 10.8071 5.15146 10.5716 5.39358L6.82212 9.26649L6.8212 9.26558C6.81229 9.27473 6.80291 9.28387 6.79308 9.29256C6.54341 9.51868 6.15747 9.49948 5.93111 9.24958L3.96711 7.07979H3.96757L3.96779 7.08002Z"
            fill="#F5F5F5"
          />
        </svg>
        </span>
        <span class="text-white">
          Copied
        </span>
      </p>
    </div>
    <!-- <div class="mt-5">
              <p>
                or download this QR code so they can scan it from their mobile device.
              </p>
            </div> -->
    <div class="flex flex-col  px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
      <div>
        <p class="font-bold font-18">
          QR Code
        </p>
        <p class="mt-2">
          Use this QR code for users to scan it from their mobile devices and access directly to this study.
        </p>
        <p class="text-primary-brown mt-4">
          <qrcode-vue
            id="qr-code"
            :value="getStudyURL"
            :size="200"
            level="H"
          />
        </p>
      </div>

      <button

        class="px-3 py-2 bg-white rounded-4p w-full md:fit-content border-gray-100 mt-5"
        @click="downloadQr"
      >
        Download
      </button>
    </div>
    <!-- <div class="mt-5">
              Website embed (iframe)
              <st-tooltip
                class="st-edit-audience__anonymous-tooltip"
                :direction="isDesktop ? 'top' : 'left'"
                :max-width="isDesktop ? 411 : 250"
                tip="Copy study URL to share as link to others"
              >
                <icon-wrapper
                  icon-name="info"
                />
              </st-tooltip>
            </div> -->

    <div class="flex flex-col px-4 py-4 rounded-8p items-start justify-between border-gray-100 ">
      <div>
        <p class="font-bold font-18">
          Study URL
        </p>
        <p class="mt-2">
          Embed this study in your website using this code.
        </p>

        <p class="text-primary-brown">
          Copy iframe code to embed study into your website.
        </p>
      </div>
      <button
        v-if="!copiedIframe"
        class="px-3 py-2 bg-white w-full md:fit-content rounded-4p border-gray-100 mt-5"
        @click="copyIframeCode"
      >
        Copy embed code
      </button>
      <p
        v-else
        class="bg-success flex space-x-2 items-center px-3 mt-5 py-2 rounded-4p disabled"
      >
        <span><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 0.875C10.3829 0.875 13.125 3.61729 13.125 7C13.125 10.3827 10.3827 13.125 7 13.125C3.61729 13.125 0.875 10.3827 0.875 7C0.875 3.61729 3.61729 0.875 7 0.875ZM3.96779 7.08002C3.74167 6.82989 3.76111 6.44395 4.01078 6.21806C4.26091 5.99193 4.64685 6.01137 4.87275 6.26104L6.39845 7.94679L9.69542 4.54122C9.93092 4.29909 10.3175 4.29383 10.5597 4.52933C10.8018 4.76483 10.8071 5.15146 10.5716 5.39358L6.82212 9.26649L6.8212 9.26558C6.81229 9.27473 6.80291 9.28387 6.79308 9.29256C6.54341 9.51868 6.15747 9.49948 5.93111 9.24958L3.96711 7.07979H3.96757L3.96779 7.08002Z"
            fill="#F5F5F5"
          />
        </svg>
        </span>
        <span class="text-white">
          Copied
        </span>
      </p>
    </div>
  </div>
  <div
    v-else
    class="bx--col-sm-9 tw-mx-auto tw-mt-20 tw-pb-20"
  >
    <p class="font-xl font-600 tw-w-full ">
      Integrate in your website
    </p>
    <p class="tw-mt-2">
      To install this widget in your website please follow the next steps.
    </p>
    <p class="tw-flex tw-flex-col tw-space-y-2 tw-my-2">
      <span class="tw-text-very-positive tw-font-semibold">
        Step 01
      </span>
      <span>
        Copy and paste the following code into your <span class="tw-text-very-positive"> &lt;head&gt; &lt;/head&gt; </span>  tag of your html page.
      </span>
    </p>
    <div class="tw-bg-[#2E2E2E] tw-flex tw-justify-between tw-items-center tw-rounded-2xl tw-px-4">
      <pre class="tw-text-white">

  &lt;script&gt;
    const _ahConfig = {
        studyID: {{ survey.id }},
        token: "{{ survey.widgetConfig.token ? survey.widgetConfig.token: '' }}"
      };
&lt;/script&gt;
&lt;script src="https://cdn.askhumans.io/main.js" defer&gt;&lt;/script&gt;
              </pre>

      <div>
        <button
          class="tw-bg-[#2E2E2E] tw-text-gray-100 tw-py-2 tw-px-4 tw-rounded-md tw-font-semibold tw-mt-2 tw-border tw-border-gray-100 tw-border-solid"
          @click="copyScript"
        >
          {{ copiedScript ? 'Copied' : 'Copy script' }}
        </button>
      </div>
    </div>
    <p class="tw-flex tw-flex-col tw-space-y-2 tw-my-5">
      <span class="tw-text-very-positive tw-font-semibold">
        Step 02 (Optional)
      </span>
      <span>
        Call this function  in the same script with the logged in user’s information as soon as you have the user’s information.
      </span>
    </p>
    <div class="tw-bg-[#2E2E2E] tw-flex tw-justify-between tw-items-center tw-rounded-2xl tw-px-4">
      <pre class="tw-text-white">

  &lt;script&gt;

  const _ahConfig = {
        studyID: {{ survey.id }},
        token: "{{ survey.widgetConfig.token ? survey.widgetConfig.token: '' }}"
      };

   <span class="tw-text-gray-500">// replace the following with the logged in user’s information </span>

    setUserDetails({
      firstName: "John",
      lastName: "Doe",
      email: "johndoe@example.com",
    });

&lt;/script&gt;
              </pre>
      <!-- <div>
              <button
                class="tw-bg-[#2E2E2E] tw-text-gray-100 tw-py-2 tw-px-4 tw-rounded-md tw-font-semibold tw-mt-2 tw-border tw-border-gray-100 tw-border-solid"
              >
                Copy script
              </button>
            </div> -->
    </div>

    <div />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      copied: false,
      copiedIframe: false,
      copiedScript: false
    }
  },
  computed: {
    getStudyURL () {
      const baseUrl = window.location.origin
      return `${baseUrl}/study/${this.survey.id}`
    }

  },
  mounted () {
  },

  methods: {
    copyScript () {
      const textArea = document.createElement('textarea')
      const startingScriptSurvey = `<script>  const _ahConfig = {
        studyID: ${this.survey.id},
        token: "${this.survey.widgetConfig.token ? this.survey.widgetConfig.token : ''}",
      }; `
      const endingScriptSurvey = `${'</'}script> \n`
      const newline = String.fromCharCode(13, 10)
      const startingScriptCDN = `${newline} <script src="https://cdn.askhumans.io/main.js">`
      const endingScriptCDN = `${'</'}script>`
      textArea.innerText = startingScriptSurvey + endingScriptSurvey + startingScriptCDN + endingScriptCDN
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      this.copiedScript = true
    },
    copyToClipboard () {
      const url = this.getStudyURL
      navigator.clipboard.writeText(url)
      this.copied = true
    },
    copyIframeCode () {
      const iframeCode = `<iframe
  src="${window.location.origin}/study/${this.survey.id}"
  title="AskHumans Embedded Survey"
  style="width:1000px;height:800px;border: 0px"
  allow="microphone"
>
</iframe>`
      navigator.clipboard.writeText(iframeCode)
      this.copiedIframe = true
    },
    downloadQr () {
      const link = document.createElement('a')
      link.download = `${this.survey.name}-qr-code.png`
      link.href = document.getElementById('qr-code').childNodes[0].toDataURL()
      link.click()
    }
  }
}
</script>
