<template>
  <div class="">
    <!-- <div
      v-if="showQuestionsPanel"
      class="st-new-question__questions-added"
    >
      <p class="st-new-question__mobile-questions-title st-new-question__mobile-questions-title--edit-survey">
        Questions
      </p>
      <draggable
        v-model="questions"
        v-bind="dragOptions"
        class="st-new-question__new-tile-scroll-container"
        @end="handleEndDraggable"
      >
        <transition-group
          tag="div"
          :class="{
            'st-new-question__scroll-area': true,
            'st-new-question__scroll-area--empty': questions.length === 0,
          }"
        >

          <div
            v-if="questions.length === 0 && !isPreview"
            key="add"
            class="st-new-question__empty-icon-and-instructions"
          >
            <div class="st-new-question__empty-icon" />
            <p class="st-new-question__add-instructions">
              Add content by selecting it from the menu on your right
            </p>
          </div>
        </transition-group>
      </draggable>
      <div
        v-if="questions.length > 0 && !isPreview"
        class="st-new-question__add-question"
      >
        <div
          class="st-new-question__add-question-text"
          @click="addQuestion"
        >
          <icon-wrapper
            class="st-new-question__add-question-icon"
            icon-name="plus"
            :invert="true"
          />
          Add Question
        </div>
      </div>
    </div> -->
    <div class="tw-mt-20">
      <!-- <new-metric-tile
        v-for="(question, idx) in questions"
        :key="question.id"
        :index="idx"
        :is-preview="isPreview"
        @setActive="setActive(idx)"
        @openDeleteModal="deleteModal = true"
        @showSpecifications="showSpecifications"
      /> -->
      <preview-bar :survey-id="survey.id" :show-preview="isStudyBeingCreated" />
      <!-- <div class="flex w-full py-3 justify-end relative">
          <button
            class="bg-white p-3 border-gray-50 border-2p rounded-4p cursor-pointer"

            @click="showPreviewModal = true"
          >
            Preview and try this study yourself
          </button>
          <div
            class="absolute max-w-300p bg-white top-16 rounded-12p px-5 py-4 "
            style="z-index: 1000; box-shadow: 5px 5px 40px rgba(24, 50, 77, 0.5);"
          >
            <p class="font-18p font-600 py-2">
              scan this QR code with your smartphone to preview this study
            </p>
            <div class="my-2">
              <qrcode-vue
                id="qr-code"
                :value="getStudyURL"
                :size="250"
                level="H"
              />
            </div>
          </div>
        </div> -->
      <!-- <widget-steps
        v-if="!isStudyBeingCreated"
        hide
        :step="2"
        :survey-id="survey.id"
      />
      <steps
        v-if="isStudyBeingCreated"
        :step="4"
        :survey-id="survey.id"
      /> -->

      <div class="tw-flex tw-w-full ">
        <study-sidebar :current-step="2" :survey-id="survey.id" :survey="survey"></study-sidebar>
        <!-- <div
        class="bx--row st-new-question__back-and-save-links-container"
      >
        <div
          class="mobile-header st-new-question__back-and-save-links st-new-question__back-and-save-links--mobile"
        >
          <a
            href="/welcome"
          >
            <icon-wrapper
              class="mobile-header__spacetrics-icon"
              icon-name="spacetrics"
            />
          </a>
          <st-button
            caption="Save & Continue"
            @click="fakeSaveToHub"
          />
        </div>
        <div class="bx--col-sm-12 st-new-question__back-and-save-links">
          <st-hyperlink
            :back-link="true"
            text="Back"
            @click="handleBackLink"
          />
          <div class=" --content">
            <div class="st-edit-survey__inline-spiner-and-save">
              <st-inline-spinner
                v-if="$store.state.isInlineSpinnerVisible"
                class="st-edit-survey__desktop-inline-spinner"
              />
              <st-hyperlink
                icon-name="save"
                text="Save"
                @click="fakeSaveProgress"
              />
            </div>
            <st-button
              class="st-edit-survey__hide-save-and-continue"
              caption="Save & Continue"
              @click="fakeSaveToHub"
            />
          </div>
        </div>
      </div> -->
        <div class="tw-w-full">
          <div v-if="!isStudyBeingCreated" class=" mx-auto mt-5 md:mt-15">
            <div class="tw-fit-content  tw-mx-auto">
              <p class="tw-text-2xl tw-font-semibold tw-text-center">
                For this step, you will have 15 seconds to record your question.
              </p>
              <p class="tw-mt-3 tw-text-center">
                It is very important that you convey the question in a clear and
                concise manner. The less time you take to ask the question, the
                more likely it is that your users will complete the study.
              </p>
            </div>
            <video-recording
              :seconds="15"
              @save="saveVideo"
              :url="survey.questions[0].questionVideoUrl"
            />
            <div class="tw-flex tw-space-y-2 tw-flex-col tw-my-3">
              <p class="text-center">
                Before jumping to the next step make sure the audio quality is
                fine.
              </p>
              <p class="text-center">
                Along with your video, we will also be showing the written
                question.
              </p>
            </div>
            <div class="tw-py-2 ">
              <specifications
                class="tw-w-2/3 tw-mx-auto"
                :key="activeQuestion.id"
                :folders="folders"
                :show-followup="false"
                :show-media="false"
              />
            </div>
          </div>
          <div v-else class=" tw-w-full ">
            <!-- <div class=" mt-5 md:mt-15">
          <div class="space-x-5 w-full">
            <a
              class=""
              :href="backURL"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.49686 1.00314C9.66771 1.174 9.66771 1.451 9.49686 1.62186L4.55622 6.5625L9.49686 11.5031C9.66771 11.674 9.66771 11.951 9.49686 12.1219C9.326 12.2927 9.04899 12.2927 8.87814 12.1219L3.62814 6.87186C3.45729 6.701 3.45729 6.42399 3.62814 6.25314L8.87814 1.00314C9.049 0.832286 9.326 0.832286 9.49686 1.00314Z"
                  fill="#202020"
                />
              </svg>

            </a>
            <span class="font-28p font-600 ">
              Question Prompts & Info Screens
            </span>
          </div>
          <div class="mt-5 w-full">
            <p>
              Here you add your study content which will include at least one question prompt and optionally include slides to give respondents more information and context.
            </p>
          </div>
          <div class="mt-5 w-full  divider w-full " />
        </div> -->

            <!-- <div v-if="activeTab === SPECS_TABS.CONTENT" ref="addContent">
              <div class="bx--col-sm-9 mx-auto flex ">
                <contents
                  :metric-image-paths="metricImagePaths"
                  :survey-id="surveyId"
                  :container-type="containerType"
                  @setActive="setActive"
                  @hideContent="hideContent"
                  @show-questions="showQuestions = true"
                />
              </div>
            </div> -->
            <!-- v-if="activeTab === SPECS_TABS.SPECIFICATIONS" -->
            <div class="mx-auto tw-justify-between tw-flex">
              <div
                class="tw-flex-col  tw-overflow-y-auto tw-overflow-x-hidden tw-flex tw-px-5 xl:tw-px-10 2xl:tw-px-20 tw-max-w-full tw-py-4 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-dark-gray-400 scrollbar-hover-black "
              >
              <div class="tw-max-w-[680px]">

                <study-information :survey="survey" />
                <div class="tw-mt-10">
                  <p class="tw-text-2xl tw-text-center tw-font-semibold">
                    Question Prompts
                  </p>
                  <p
                    class="tw-text-center  tw-mx-auto tw-mt-2 tw-text-[15px] tw-text-black-two"
                  >
                    Here you add your study content which will include at least
                    one question prompt and optionally include slides to give
                    respondents more information and context.
                  </p>
                </div>
              </div>
                <draggable v-model="questions" @end="onDragEnd">
                
                  <new-metric-tile
                    v-for="(question, idx) in questions"
                    :key="question.id"
                    :folders="folders"
                    :survey="survey"
                    :index="idx"
                    :is-preview="isPreview"
                    @updateSlide="updateSlide"
                    @setActive="setActive(idx)"
                    @setInActive="setInActive(idx)"
                    @openDeleteModal="deleteModal = true"
                    @showSpecifications="showSpecifications"
                    @deleteQuestion="deleteQuestion"
                    @changeUpdatedSurvey="changeUpdateSurvey"
                  />
                </draggable>
                <div class="tw-max-w-[680px]">

                  <div class="tw-mt-3 tw-flex tw-space-x-3">
                    <ah-button
                      type="outlined"
                      @click="addMetric('opinion_metric', null)"
                    >
                      Add question
                    </ah-button>
                    <ah-button type="outlined" @click="addMetric('slide', null)">
                      Add slide
                    </ah-button>
                  </div>
                  <div
                    class="tw-flex tw-space-x-2 tw-justify-end tw-w-full tw-mt-6"
                  >
                    <!-- <div>
                      <ah-button
                        type="outlined"
                        @click="changeToStaticOrVideo('static')"
                      >
                        Change to static message
                      </ah-button>
                    </div> -->
                    <div class="tw-flex tw-space-x-2">
                      <ah-button @click="fakeSaveToHub">
                        Next step
                      </ah-button>
                    </div>
                  </div>
                </div>
                <!-- <div
              class="bg-create-group rounded-4p justify-center text-askhumans-primary px-3 py-3 flex items-center space-x-2 cursor-pointer"
              @click="addQuestion"
            >
              <span>
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1400_29380)">
                    <path
                      d="M7.5 0C3.64 0 0.5 3.14067 0.5 7C0.5 10.8593 3.64 14 7.5 14C11.36 14 14.5 10.8593 14.5 7C14.5 3.14067 11.36 0 7.5 0ZM7.5 13.2223C4.06933 13.2223 1.27767 10.431 1.27767 7C1.27767 3.569 4.069 0.777667 7.5 0.777667C10.931 0.777667 13.7223 3.569 13.7223 7C13.7223 10.431 10.9307 13.2223 7.5 13.2223V13.2223Z"
                      fill="#A6684F"
                    />
                    <path
                      d="M9.44429 6.61102H7.88863V5.05535C7.88863 4.84069 7.71463 4.66669 7.49996 4.66669C7.28529 4.66669 7.11096 4.84102 7.11096 5.05569V6.61135H5.55529C5.34063 6.61135 5.16663 6.78535 5.16663 7.00002C5.16663 7.21469 5.34096 7.38902 5.55563 7.38902H7.11129V8.94469C7.11129 9.15935 7.28563 9.33369 7.50029 9.33369C7.71496 9.33369 7.88929 9.15935 7.88929 8.94469V7.38902H9.44496C9.65963 7.38902 9.83396 7.21469 9.83396 7.00002C9.83396 6.78535 9.65963 6.61102 9.44463 6.61102H9.44429Z"
                      fill="#BE6744"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1400_29380">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>

              </span>
              <span>
                Add screen
              </span>
            </div> -->
              </div>
              <!-- <div class="tw-w-full tw-flex st-new-question__question-specifications tw-pl-20 tw-max-w-[620px] tw-mt-8">
            <study-information :survey="survey" />
            <div class="tw-mt-10">
              <p class="tw-text-2xl tw-text-center tw-font-semibold">
                Question Prompts
              </p>
              <p class="tw-text-center  tw-mx-auto tw-mt-2 tw-text-[15px] tw-text-black-two">
                Here you add your study content which will include at least one question prompt and optionally include slides to give respondents more information and context.
              </p>
            </div>
            <div
              v-if="!showAdditionalQuestionSelection"
              :class="{
                'st-specifications__header-row': true,
                'st-specifications__header-row--incomplete': !activeQuestion.complete,
              }"
            >
              <p class="st-specifications__title">
                <span>
                  {{ activeMetricName }}
                </span>
              </p>
              <div class="st-specifications__header-icons-container on-top">
                <div
                  @click="showDeleteModal = true"
                >
                  <icon-wrapper
                    class="st-specifications__header-icon st-specifications__header-icon--trash"
                    icon-name="trash"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="activeQuestion.questionType === 'slide'"
              class="flex flex-col md:flex-row"
            >
              <div class="flex flex-col w-full">
                <div class="w-full">
                  <label class="font-600">Layout*</label>
                  <st-dropdown-menu
                    container-class="h-40p mt-1 w-full border-none rounded-4p border-black w-full-important"
                    selected-class="rounded-4p border-gray-important bg-white min-w-full w-full-important"
                    :display-caption="true"
                    :initial-active-option="slideOptions[0]"
                    :options="slideOptions"
                    :class="{ ' border-gray-100 rounded-4p w-full': true }"
                    @updateOption="slideSelectHandler"
                  />
                </div>
                <div class="w-full mt-5">
                  <label class="font-600">Header*</label>
                  <st-input
                    v-model="slideHeading"
                    style-type="rounded"
                    @input="headingChangeHandler"
                    @blur="saveObject('heading')"
                  />
                 
                  <label class="font-600">Description*</label>
                  <textarea
                    v-model="slideDescription"
                    class="my-1 w-full rounded-4p px-2 py-1 font-14"
                    rows="10"
                    @blur="saveObject('description')"
                    @keyup="descriptionChangeHandler"
                  />
                 
                   <slide-controls
                  v-if="activeQuestion.questionType === 'slide'"
                  @changeTemplate="changeTemplate"
                />

                
                </div>
              </div>
              <preview-example
                :heading="slideHeading"
                :description="slideDescription"
                :type="selectedSlideOption"
                :survey-name="surveyName"
                :survey-question-count="questions.length"
              />
            </div>

           

            <div
              v-if="showAdditionalQuestionSelection"
            >
              <contents
                :metric-image-paths="metricImagePaths"
                :survey-id="surveyId"
                :container-type="containerType"
                @hideContent="hideContent"
                @setActive="setActive"
                @show-questions="showQuestions = true"
              />
              <div class="mt-5">
                <button
                  class="bg-gray-100 text-gray-two rounded-4p p-3 "
                  @click="cancelAddQuestion"
                >
                  Cancel
                </button>
              </div>
            </div>
            <specifications
              v-else
              :key="activeQuestion.id"
              :folders="folders"
            />
            
          </div> -->
              <!-- <st-hyperlink

            class="bx--col-xs-12 st-new-question__preview-link"
            text="Preview Question"
            icon-name="view-password"
            icon-class="st-hyperlink__default-icon-spacing"
            :url="`/questions/${activeQuestion.id}/preview`"
          /> -->
              <phone-preview :survey="updatedSurvey" :step="2" :active-question-id="(activeQuestion && activeQuestion.id) || currentActiveQuestionId" />
              <!-- <div class="bx--offset-xl-1 bx--col-lg-4">
          <div
            class="st-new-question__virtual-phone-preview"
          > -->
              <!-- <preview-example
          :survey-name="surveyName"
          :survey-question-count="questions.length"
        /> -->
              <!-- </div>
        </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <st-modal
      v-model="showDeleteModal"
      size-class="st-new-question__delete-question-modal"
      :non-exit-modal="true"
    >
      <template v-slot:modalTitle>
        Delete Question/Slide
      </template>
      <template v-slot:modalActions>
        <p class="tw-text-black-two tw-mt-3">
          Are you sure you want to delete this question/slide?
        </p>
      </template>
      <template v-slot:modalButton>
        <div class="tw-mt-5 tw-flex tw-justify-end tw-space-x-3">
          <ah-button type="outlined" @click="showDeleteModal = false">
            Cancel
          </ah-button>
          <ah-button @click="deleteQuestionConfirmation">
            Delete question
          </ah-button>
        </div>
      </template>
    </st-modal>
    <!-- <div class="p-5 md:padding-container  fixed bottom-0 bg-white w-full border-top-gray-100 st-edit-invitation__continue-and-preview">
      <div class="flex w-full justify-between px-2 ">
        <a
          href="/"
          class="bg-gray-100 text-gray-two rounded-4p p-3 "
        >
          Cancel
        </a>
        <div class="flex space-x-2 md:space-x-5">
          <div class="st-edit-survey__inline-spiner-and-save">
            <st-inline-spinner
              v-if="$store.state.isInlineSpinnerVisible"
              class="st-edit-survey__desktop-inline-spinner"
            />
            <button
              icon-name="save"
              text="Save"
              class="bg-create-group rounded-4p px-3 py-3 font-16p text-blue-primary"
              @click="fakeSaveProgress"
            >
              Save draft
            </button>
          
          </div>

          <st-button
            :caption="'Save & Continue'"
            :disabled="buttonDisabled"
            @click="fakeSaveToHub"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { SPECS_TABS } from "../../../utilities/enums";
import {
  IconWrapper,
  StButton,
  StHyperlink,
  StInlineSpinner,
  AhButton,
  StModal,
  StTooltip,
  StInput,
  StDropdownMenu,
  StMediaUploader,
  PhonePreview,
} from "../../common";
import { QUESTION_DESCRIPTIONS, SLIDE_TYPES } from "./constants";
import { isDesktop, updateSlideQuestionObject } from "../../../mixins";
import updateQuestion from "../../../mixins/question/updateQuestion.vue";
import Contents from "./contents";
import NewMetricTile from "./new-metric-tile";
import PreviewExample from "./preview-example";
import Specifications from "./specifications";
import SlideControls from "./question_specifications/slide/slide-controls";
import Steps from "./steps.vue";
import WidgetSteps from "./widget-steps.vue";
import saveUpdates from "./saveUpdates";
import PreviewBar from "./preview-bar.vue";
import StudySidebar from "./study-sidebar.vue";
import StudyInformation from "./study-information.vue";
import VideoRecording from "./video-recording.vue";
import { DirectUpload } from "@rails/activestorage";
export default {
  components: {
    Contents,
    draggable,
    StMediaUploader,
    AhButton,
    StDropdownMenu,
    Steps,
    WidgetSteps,
    IconWrapper,
    NewMetricTile,
    PreviewExample,
    Specifications,
    StButton,
    StHyperlink,
    PhonePreview,
    StInlineSpinner,
    VideoRecording,
    StModal,
    StTooltip,
    StInput,
    SlideControls,
    PreviewBar,
    StudySidebar,
    StudyInformation,
  },
  mixins: [
    isDesktop,
    updateQuestion,
    saveUpdates,
    updateQuestion,
    updateSlideQuestionObject,
  ],
  props: {
    activeQuestionId: {
      type: Number,
      required: false,
      default: -1,
    },
    initialQuestions: {
      default() {
        return [];
      },
      type: Array,
      required: false,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    metricImagePaths: {
      type: Array,
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },

    surveyId: {
      type: Number,
      required: true,
    },
    survey: {
      type: Object,
      required: false,
    },
    containerType: {
      type: String,
      required: false,
      default: "Survey",
    },
    folders: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      updatedSurvey: this.survey,
      currentActiveQuestionId: null,
      slideOptions: Object.values(SLIDE_TYPES),
      selectedSlideOption: SLIDE_TYPES[0],
      deleteModal: false,
      showImageButton: false,
      QUESTION_DESCRIPTIONS,
      SPECS_TABS,
      surveyName: this.name,
      slideHeading: "",
      slideDescription: "",
      showDeleteModal: false,
      showQuestions: false,
      showAdditionalQuestionSelection: false,
      recordedChunks: [],
      deleteQuestionId: null,
    };
  },
  computed: {
    ...mapState("specifications", ["activeQuestionIdx", "activeTab"]),
    ...mapGetters("specifications", [
      "activeQuestion",
      "objects",
      "activeObject",
      "questionCount",
    ]),
    ...mapState(["directUploadUrl"]),

    backURL() {
      return `/study/${this.survey.id}/edit/landing_page`;
    },
    isStudyBeingCreated() {
      return this.survey.surveyType === "study";
    },
    buttonDisabled() {
      const allQUestionsCompleted = this.questions.every((quest) => {
        return quest.complete;
      });

      return !allQUestionsCompleted;
    },
    activeMetricId() {
      if (this.activeQuestionIdx === -1) {
        return null;
      }

      return this.activeQuestion.id;
    },
    activeMetricName() {
      const questionIndex = this.questions
        .filter((ques) => ques.questionType !== "slide")
        .findIndex((ques) => {
          return ques.id === this.activeQuestion.id;
        });
      if (this.activeQuestion.questionType == "slide") {
        return "slide";
      }
      return `Question ${questionIndex + 1}`;
      // return this.activeQuestion.questionType === 'culture_board' ? 'Image Board' : this.activeQuestion.questionType.split('_').join(' ')
    },
    activeMetricDescription() {
      return this.QUESTION_DESCRIPTIONS.filter((list) => {
        return list.questionType === this.activeQuestion.questionType;
      })[0];
    },
    allQuestionsComplete: function() {
      return this.questions.every((question) => {
        return question.complete;
      });
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        forceFallback: true,
      };
    },
    noQuestions: function() {
      return this.questions.length === 0;
    },
    questionIds() {
      return this.questions.map((question) => question.id);
    },
    questions: {
      get() {
        return this.$store.state.specifications.questions;
      },
      set(newQuestions) {
        this.$store.dispatch("specifications/reindexQuestions", {
          newQuestions: newQuestions,
          surveyId: this.surveyId,
        });
      },
    },
    showQuestionsPanel() {
      return this.isDesktop ? true : this.showQuestions;
    },
  },
  mounted() {
    let activeQuestionIdx = this.initialQuestions
      .map((x) => x.id)
      .indexOf(this.activeQuestionId);
    if (this.survey.survey_type !== "study") {
      this.$store.commit("toggleFullSpinner", true);
      setTimeout(() => {
        this.addQuestionAndSetActive("opinion_metric"), 300;
      });
    }
    if (this.initialQuestions.length) {
      this.setActiveQuestionIdx(this.activeQuestionId);
      this.showQuestions = false;
      this.changeTab(SPECS_TABS.SPECIFICATIONS);
      let state = { survey_id: this.surveyId, question_type: "opinion_metric" };
      let title = "opinion metric";
      let url =
        "?question=" +
        this.initialQuestions[0].id +
        "&type=" +
        "opinion_metric";
      window.history.replaceState(state, title, url);
      setTimeout(() => {
        this.setActive(0);
      }, 200);
    }
    // if (this.initialQuestions.length) {
    //   this.changeTab(SPECS_TABS.SPECIFICATIONS);
    // }
    if (!this.isDesktop && this.initialQuestions.length > 0) {
      this.showQuestions = true;
    }
    this.replaceQuestions(this.initialQuestions);
    this.setActiveQuestionIdx(activeQuestionIdx);
    this.$store.commit("hideSidebar", true);
  },

  methods: {
    ...mapMutations("specifications", [
      "changeTab",
      "setActiveQuestionIdx",
      "appendQuestion",
      "replaceQuestions",
      "resetSlideColors",
      "addImage",
      "addObject",
      "setActiveObject",
      "updateObject",
      "updatePriorities"
    ]),
    ...mapActions("specifications", [
      "deleteQuestionIdx",
      "setCurrentTemplate",
    ]),
    onDragEnd(event) {
      // Handle the reordering of questions here
      const updatedQuestionIds = this.questions.map((question) => question.id);
      updatePriorities(event, this.questions, updatedQuestionIds, this.survey.id)

      // console.log('Questions reordered', this.questions);
    },
    saveVideo(chunks) {
      this.recordedChunks = chunks;
    },
    deleteQuestion(id) {
      this.deleteQuestionId = id;
      this.showDeleteModal = true;
    },


    updateSlide( data){
      // console.log(data.id)
      const question = this.updatedSurvey.questions.find(
        (question) => question.id === data.id
      );
      // console.log("question", question)
      if(Object.keys(data).includes('heading')){
        const headingObj = question.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==0)
        headingObj.content = data.heading
        question.slideQuestionObjects = question.slideQuestionObjects.map((obj) => {
          if (obj.id === headingObj.id) {
            return headingObj;
          }
          return obj;
        });
      }
      else if(Object.keys(data).includes('description')){
        // question.slideQuestionObjects[1].content = data.description
        const descriptionObj = question.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==10)
        descriptionObj.content = data.description
        question.slideQuestionObjects = question.slideQuestionObjects.map((obj) => {
          if (obj.id === descriptionObj.id) {
            return descriptionObj;
          }
          return obj;
        });
      }
      
      this.updatedSurvey.questions = this.updatedSurvey.questions.map((q) => {
        if (question.id === data.id) {
          return question;
        }
        return q;
      });
    },

    addQuestionAndSetActive(questionType) {
      if (this.questionCount) {
        this.$store.commit("toggleFullSpinner", false);
        return;
      }
      let type = this.containerType == "slides" ? "slide" : "study";
      let url = `/${type}/` + this.surveyId + "/questions";
      let data = {
        container_id: this.surveyId,
        container_type: this.containerType,
        question_type: questionType,
        priority: this.questionCount,
      };
      this.$store.commit("toggleInlineSpinner", true);

      this.$axios
        .request({
          url: url,
          method: "post",
          data: { question: data },
        })
        .then((response) => {
          this.$store.commit("toggleFullSpinner", false);
          this.$store.commit("toggleInlineSpinner", false);
          this.appendQuestion(response.data);
          this.setActive(0);
        })
        .catch((error) => {
          console.log("error", error);
          this.$store.commit("toggleInlineSpinner", false);
          this.$store.commit("showError", error);
        });
    },
    buildDirectUpload() {
      if (this.recordedChunks.length === 0) return Promise.resolve();
      const blob = new Blob(this.recordedChunks, { type: "video/webm" });
      const file = new File([blob], "blob", { type: "video/webm" });
      return this.buildRecordData(file)
        .then((recordData) => {
          return this.$axios.request({
            method: "patch",
            url: `/questions/${this.activeQuestion.id}`,
            data: recordData,
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    buildRecordData(file) {
      let updateRecordData = new FormData();
      return this.directUpload(file)
        .then((signedId) => {
          updateRecordData.append("question[question_video]", signedId);
          return updateRecordData;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    directUpload(uploadFile) {
      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(uploadFile, this.directUploadUrl);
        upload.create((error, blob) => {
          error ? reject(error) : resolve(blob.signed_id);
        });
      });
    },
    addQuestion() {
      this.setActiveQuestionIdx(-1);
      this.showQuestions = false;
      this.showAdditionalQuestionSelection = true;
    },
    headingChangeHandler(text) {
      this.updateObject({
        type: "text",
        id: this.objects[0]?.id,
        content: text,
      });
    },
    descriptionChangeHandler(e) {
      this.updateObject({
        type: "text",
        id: this.objects[1]?.id,
        content: e.target.value,
      });
    },
    changeUpdateSurvey(question){
      // find question in updatedSurvey and update it
      let questionIndex = this.updatedSurvey.questions.findIndex((q) => q.id === question.id)
      // console.log("question index", questionIndex)
      if(questionIndex === -1){
        this.updatedSurvey.questions.push(question)
      }
      else{
        this.updatedSurvey.questions[questionIndex] = question
      }
      // console.log("found question", this.updatedSurvey.questions[questionIndex])
      this.updatedSurvey.questions = this.updatedSurvey.questions.map((q) => {
        if (q.id === question.id) {
          return question;
        }
        return q;
      });

    },
    addNewObject(type, field) {
      let object = {};

      const { slideQuestionObjects } = this.activeQuestion;
      // const containerWidth = document.querySelector('.st-slides__canvas').offsetWidth
      // const containerHeight = document.querySelector('.st-slides__canvas').offsetHeight
      // getting index of new element
      // console.log('go and add object', slideQuestionObjects, this.activeQuestionId)
      // console.log("type", type, "field", field)
      const newIndex = slideQuestionObjects?.length;
     
      if (type === "text") {
        // console.log("adding text");
        object = {
          type,
          index: newIndex || 0,
          content: "",
          x: 5,
          y: field == "heading" ? 0 : 10,
          pxX: "5px",
          pxY: "5px",
          question_id: this.activeQuestion.id,
          object_index: newIndex || 0,
          slide_object_type: type,
          x_position: 0,
          y_position: 0,
          // pxWidth: object.width * containerWidth / 100 + 'px',
          // pxHeight: object.height * containerHeight / 100 + 'px'
        };
        if (field == "description") object.y_position = 10;
        
          this.createNewObject({ slide_question_object: object }, null, (res) => {

            // console.log("response............",this.updatedSurvey)
            const question = this.updatedSurvey.questions.find(
              (question) => question.id === this.activeQuestion.id
            );
            // console.log("question before slide objects",question)
            question.slideQuestionObjects = res.question.slideQuestionObjects;
            // console.log("question after adding slide objects",question)
          this.addObject({ ...object, id: res.id });
        });
      }
    },
    saveObject(field) {
      let object = {};
      if (field == "heading") {
        object = this.objects[0];
      } else if (field == "description") {
        object = this.objects[1];
      }
      const current = this.objects.find((obj) => {
        return obj.id === object.id;
      });

      this.updateSlideQuestionObject(object.id, current.index, {
        slide_question_object: { content: object?.content, y: object.y },
      });
    },
    slideSelectHandler(option) {
      if (option.includes("Image")) {
        this.showImageButton = true;
      } else {
        this.showImageButton = false;
      }
      this.selectedSlideOption = option;
    },
    cancelAddQuestion() {
      this.hideContent();
      this.setActiveQuestionIdx(this.questions.length - 1);
    },
    hideContent(questionType) {
      this.slideHeading = "";
      this.slideDescription = "";
      if (questionType === "slide") {
        setTimeout(()=>{
          this.addNewObject('text', "heading")
          
        }, 200);
        setTimeout(()=>{
          this.addNewObject('text', "description")
        },1000)
      }
      this.showAdditionalQuestionSelection = false;
    },
    handleBackLink() {
      if (!this.isDesktop && !this.showQuestions && this.questions.length > 0) {
        this.showQuestions = true;
      } else {
        this.rerouteViaBackLink();
      }
    },
    rerouteViaBackLink() {
      window.location =
        this.containerType === "Survey"
          ? `/study/${this.surveyId}/edit`
          : `/templates`;
    },
    deleteQuestionConfirmation() {
      const questionIndex = this.questions.findIndex((ques) => {
        return ques.id === this.deleteQuestionId;
      });

      this.deleteQuestionIdx({
        questionIdx: questionIndex,
        surveyId: this.surveyId,
      })
        .then(() => {
          this.showDeleteModal = false;
          console.log("active question", this.activeQuestion)
          if(!this.activeQuestion){
            this.setActiveQuestionIdx(0)
            return 
          }
          let questionType = this.activeQuestion.questionType;
          
          let state = { survey_id: this.surveyId, question_type: questionType };
          let title = this.activeQuestion.questionType;
          let url =
            "?question=" + this.activeQuestion.id + "&type=" + questionType;

          window.history.replaceState(state, title, url);
          this.updatedSurvey.questions = this.updatedSurvey.questions.filter(
            (question) => {
              return question.id !== this.deleteQuestionId;
            }
          );
        })
        .catch((err) => console.log(err));
      this.slideHeading = "";
      this.slideDescription = "";
    },
    fakeSaveProgress() {
      // Real save done when modifying content
      this.$store.commit("toggleInlineSpinner", true);
      if (this.recordedChunks.length) {
        this.buildDirectUpload();
      }
      setTimeout(() => {
        this.$store.commit("toggleInlineSpinner", false);
      }, 2000);
    },
    updateSurvey() {
      // Real save done when modifying content
      this.$store.commit("toggleInlineSpinner", true);
      setTimeout(() => {
        this.$store.commit("toggleInlineSpinner", false);
      }, 2000);
    },
    fakeSaveToHub() {
      // Real save done when modifying content
      this.$store.commit("toggleFullSpinner", true);
      if (this.recordedChunks.length) {
        this.buildDirectUpload().then(() => {
          if(this.survey.reward){
            window.location = "incentives";
          }
          else{
            window.location = "segments";
          }
        });
        return;
      }
     
      if (this.survey.reward) {
        window.location = "incentives";
      }
      else{
        window.location = "segments";
      }
      // }
      // this.$nextTick(() => { this.rerouteViaBackLink() })
    },
    handleEndDraggable(ev) {
      this.setActive(ev.newIndex);
    },
    setInActive(idx){
      if(this.activeQuestionIdx == idx){
        this.setActiveQuestionIdx(-1)
        return;
      }
    },
    setActive(idx) {

      this.changeTab(SPECS_TABS.SPECIFICATIONS);
      this.setActiveQuestionIdx(idx);
      this.showQuestions = false;
      this.showAdditionalQuestionSelection = false;
      let state = { survey_id: this.surveyId, question_type: "opinion_metric" };
      let title = "opinion_metric";
      let url =
        "?question=" + this.questions[idx].id + "&type=" + "opinion_metric";

      window.history.replaceState(state, title, url);
      // console.log(this.activeQuestion)
      if(this.activeQuestion.questionType !=='slide'){
        return 
      }
      setTimeout(() => {
        // console.log("objects", this.objects, this.activeQuestion)
        const headingObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==0)
        if(headingObj){
          this.slideHeading = headingObj.content;
        }
        // this.slideHeading = this.objects[0]?.content;
        
        // console.log(this.objects.length)
        // if(this.objects.length>=3){
        //   this.selectedSlideOption = this.selectedSlideOption(this.slideOptions[1])
        //   console.log("sfdsfs")
        // }
      }, 300);
      setTimeout(()=>{
        const descriptionObj = this.activeQuestion.slideQuestionObjects.find((obj)=>Number(obj.yPosition)==10)
        this.slideDescription = descriptionObj.content;
        // this.slideDescription = this.objects[1]?.content;
      },600)
    },
    showSpecifications(boolean) {
      if (boolean) {
        this.changeTab(SPECS_TABS.SPECIFICATIONS);
      }
    },
    handleSave() {
      this.saveAndToHub("segments");
    },
    uploadComplete(payload) {
      const containerWidth = 100
      const containerHeight = 100
      // const containerWidth = document.querySelector(".st-slides__canvas")
      //   .offsetWidth;
      // const containerHeight = document.querySelector(".st-slides__canvas")
      //   .offsetHeight;

      payload.forEach((object) => {
        this.addObject({
          id: object.id,
          index: object.objectIndex,
          type: object.slideObjectType,
          blob: object.mediaUrl,
          width: object.width,
          height: object.height,
          x: object.xPosition,
          y: object.yPosition,
          pxWidth: (object.width * containerWidth) / 100 + "px",
          pxHeight: (object.height * containerHeight) / 100 + "px",
          pxX: (object.xPosition * containerWidth) / 100 + "px",
          pxY: (object.yPosition * containerHeight) / 100 + "px",
        });
      });
      this.isUploadOpen = false;
    },
    appendQuestionAndFocus(questionData) {
      // console.log("questionData", questionData)
      this.updatedSurvey.questions.push(questionData);
      this.currentActiveQuestionId = questionData.id;
      this.appendQuestion(questionData);
      this.setActive(this.questionCount - 1);
    },

    addMetric(questionType, optionsCount = 0) {
      let type = this.containerType == "slides" ? "slide" : "study";
      let url = `/${type}/` + this.surveyId + "/questions";
      let data = {
        container_id: this.surveyId,
        container_type: this.containerType,
        question_type: questionType,
        priority: this.questionCount,
      };
      this.$store.commit("toggleInlineSpinner", true);
      
      this.$axios
        .request({
          url: url,
          method: "post",
          data: { question: data },
        })
        .then((res) => {
          if(questionType=="slide"){
       setTimeout(()=>{
        this.addNewObject("text", "heading");
       },200);
       setTimeout(()=>{
        this.addNewObject("text", "description");
       },250);
      }
          this.$emit("hideContent", questionType);
          if (optionsCount > 0) {
            // add options
            let optionUrl = "/questions/" + res.data.id + "/question_options/";
            for (let i = 1; i <= optionsCount; i++) {
              this.$axios
                .request({
                  url: optionUrl,
                  method: "post",
                  data: {
                    question_option: { question_id: res.data.id, priority: i },
                  },
                })
                .then((res) => {
                  if (i === optionsCount) {
                    this.appendQuestionAndFocus(res.data.question);
                  }
                })
                .catch((err) => {
                  console.log(err.response.data);
                });
            }
          } else {
            this.appendQuestionAndFocus(res.data);
          }
          setTimeout(() => {
            this.$store.commit("toggleInlineSpinner", false);
          }, 2450);
        })
        .catch((err) => {
          console.log(err.response);
          // TODO: This is just to make culture_board question work
          // and should be removed after creating response from backend
          const data = {
            askForExplanation: null,
            canSkip: true,
            complete: false,
            description: null,
            externalVideoUrl: null,
            hasRooms: false,
            id: 123,
            imageUrl: null,
            matterportId: null,
            maxXLabel: null,
            maxYLabel: null,
            mediaUrl: null,
            minXLabel: null,
            minYLabel: null,
            name: null,
            options: [],
            panoskinId: null,
            priority: 0,
            questionType: "image_board",
            scaleLength: null,
            scaleStyle: null,
            surveyId: 37,
            url: "http://localhost:3000/questions/97.json",
          };
          this.appendQuestionAndFocus(data);
        });
    },
    changeTemplate(template) {
      const confirm = window.confirm("Some changes will be lost");
      if (confirm) {
        this.setCurrentTemplate(template);
        this.updateQuestion("slide_template_key", template);
        setTimeout(() => {
          this.updateQuestion("slide_colors", []);
        }, 1000);
        this.resetSlideColors();
      }
    },
  },
};
</script>
<style scoped>
/* .on-top{
  z-index:999;
} */
</style>
